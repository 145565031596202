/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from 'react';
import Box from '@mui/joy/Box';
import Link from '@mui/joy/Link';
import Table from '@mui/joy/Table';
import Sheet from '@mui/joy/Sheet';
import Checkbox from '@mui/joy/Checkbox';
import Typography from '@mui/joy/Typography';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Trans } from "@lingui/macro";
import { Pagination } from './Pagination';
import { useSearchParams } from "react-router-dom";
import { Link as RouterLink } from "react-router-dom";
import { formatDateWithAgo } from '../utils/dateUtils';
import { FilePrefilter, FileSortField, SortDirection } from './ListTypes';
import { ConfirmDeleteButton } from './ConfirmDeleteButton';
import { File } from '../models/File';
import { IconButton } from '@mui/joy';
import PlayIcon from '@mui/icons-material/PlayCircle';
import { DownloadForOffline } from '@mui/icons-material';

export function FileTable({ files = [], skip = 0, take = 20, totalCount, sort, sortDirection = SortDirection.Ascending, prefilter, onSelectFile, onDownloadFile }: { files: File[], skip: number, take: number, totalCount: number, sort: FileSortField, sortDirection: SortDirection, prefilter: FilePrefilter, onSelectFile?: Function | undefined, onDownloadFile?: Function | undefined }) {
  const [selected, setSelected] = React.useState<string[]>([]);
  const [searchParams, setSearchParams] = useSearchParams();

  const setSort = (sort: FileSortField) => {
    searchParams.set("sort", sort);
    setSearchParams(searchParams);
  }

  const setSortDirection = (sortDirection: SortDirection) => {
    searchParams.set("sortDirection", sortDirection);
    setSearchParams(searchParams);
  }

  const SortLink = ({ activeSort, activeSortDirection, columnName, children }: React.PropsWithChildren<{ activeSort: FileSortField, activeSortDirection: SortDirection, columnName: FileSortField }>) => {
    return (<Link
      underline="none"
      color={activeSort === columnName ? "primary" : "neutral"}
      component="button"
      onClick={() => {
        setSort(columnName);
        setSortDirection(activeSortDirection === SortDirection.Ascending ? SortDirection.Descending : SortDirection.Ascending);
      }
      }
      fontWeight="lg"
      endDecorator={activeSort === columnName ?<ArrowDropDownIcon /> : <></>}
      sx={{
        '& svg': {
          transition: '0.2s',
          transform:
            sortDirection === 'Descending' ? 'rotate(0deg)' : 'rotate(180deg)',
        },
      }}
    >
      {children}
    </Link>);
}
  return (
    <React.Fragment>
      <Sheet
        className="OrderTableContainer"
        variant="outlined"
        sx={{
          display: { xs: 'none', sm: 'initial' },
          width: '100%',
          borderRadius: 'sm',
          flexShrink: 1,
          overflow: 'auto',
          minHeight: 0,
        }}
      >
        <Table
          aria-labelledby="tableTitle"
          stickyHeader
          hoverRow
          sx={{
            '--TableCell-headBackground': 'var(--joy-palette-background-level1)',
            '--Table-headerUnderlineThickness': '1px',
            '--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
            '--TableCell-paddingY': '4px',
            '--TableCell-paddingX': '8px',
          }}
        >
          <thead>
            <tr>
              <th style={{ width: 48, textAlign: 'center', padding: '12px 6px' }}>
                <Checkbox
                  size="sm"
                  indeterminate={
                    selected.length > 0 && selected.length !== files.length
                  }
                  checked={selected.length === files.length}
                  onChange={(event) => {
                    setSelected(
                      event.target.checked ? files.map((row) => row.id) : [],
                    );
                  }}
                  color={
                    selected.length > 0 || selected.length === files.length
                      ? 'primary'
                      : undefined
                  }
                  sx={{ verticalAlign: 'text-bottom' }}
                />
              </th>
              <th style={{ width: 120, padding: '12px 6px' }}>
                <SortLink activeSort={sort} activeSortDirection={sortDirection} columnName={FileSortField.FileName}>
                  <Trans>Filename</Trans>
                </SortLink>
              </th>
              <th style={{ width: 140, padding: '12px 6px' }}>
                <SortLink activeSort={sort} activeSortDirection={sortDirection} columnName={FileSortField.CreatedAt}>
                  <Trans>Created At</Trans>
                </SortLink>
              </th>
              <th style={{ width: 140, padding: '12px 6px' }}> </th>
            </tr>
          </thead>
          <tbody>
            {files.map((row) => (
              <tr key={row.id}>
                <td style={{ textAlign: 'center', width: 120 }}>
                  <Checkbox
                    size="sm"
                    checked={selected.includes(row.id)}
                    color={selected.includes(row.id) ? 'primary' : undefined}
                    onChange={(event) => {
                      setSelected((ids) =>
                        event.target.checked
                          ? ids.concat(row.id)
                          : ids.filter((itemId) => itemId !== row.id),
                      );
                    }}
                    slotProps={{ checkbox: { sx: { textAlign: 'left' } } }}
                    sx={{ verticalAlign: 'text-bottom' }}
                  />
                </td>
                <td>
                  <Typography level="body-xs">{row.fileName}</Typography>
                </td>
                <td>
                  <Typography level="body-xs">{formatDateWithAgo(row.createdAt)}</Typography>
                </td>
                <td>
                  <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                    {onSelectFile && <IconButton onClick={() => onSelectFile(row)} aria-label="edit">
                      <PlayIcon />
                    </IconButton>}
                    {onDownloadFile && <IconButton onClick={() => onDownloadFile(row)} aria-label="edit">
                      <DownloadForOffline />
                    </IconButton>}
                  </Box>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Sheet>
      <Pagination take={take} skip={skip} totalCount={totalCount} prefilter={prefilter as string} sort={sort} sortDirection={sortDirection} />
    </React.Fragment>
  );
}
