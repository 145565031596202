/**
 * Returns the normalized click coordinates of the video
 * This considers the fact that the video is rendered in contains mode.
 * The video element is thereby scaled to the maximum available space and the video is centered scaled inside to the maximum available space respecting its aspect ratio.
 * This leads to space eather on top and bottom or on left and right of the video.
 * The x,y coordinates that the browser provides are relative to the video element but not to the video rendered in contains mode inside it.
 * This method returns the real x,y coodinates only of the scaled rendered video
 */
const getClickCoordinatesOnVideo = (e: MouseEvent) => {
  const videoElement = (e.target! as HTMLVideoElement);
  const videoBounds = videoElement.getBoundingClientRect();

  const scaledVideoSize = getScaledSizeOfVideoRenderedContained(videoElement, videoBounds);
  const centerSpacing = getCenterSpacingOfVideo(videoBounds, scaledVideoSize);
  const offsetedCoordinates = offsetCoordinates(e, centerSpacing);
  if (!checkBoundsOfCoordinates(offsetedCoordinates, scaledVideoSize)) {
    return null;
  }
  const coordinates = normalizeCoordinates(offsetedCoordinates, scaledVideoSize);
  return coordinates;
}

/**
 * Calculates the scaled size of the video rendered in contains mode inside the video element
 */
const getScaledSizeOfVideoRenderedContained = (videoElement: HTMLVideoElement, videoBounds: DOMRect) => {
  const mediaAspectRatio = videoElement.videoWidth / videoElement.videoHeight;
  const boundsAspectRatio = videoBounds.width / videoBounds.height;

  return {
    width: mediaAspectRatio > boundsAspectRatio ? videoBounds.width : videoBounds.height * mediaAspectRatio,
    height: mediaAspectRatio < boundsAspectRatio ? videoBounds.height : videoBounds.width / mediaAspectRatio,
  }
}

/**
 * Gets the spacing around the rendered video inside the video element
 */
const getCenterSpacingOfVideo = (videoBounds: DOMRect, scaledVideoSize: { width: number, height: number }) => {
  return {
    x: (videoBounds.width - scaledVideoSize.width) / 2,
    y: (videoBounds.height - scaledVideoSize.height) / 2,
  }
}

/**
 * Offsets the coordinates by the spacing around the video
 */
const offsetCoordinates = (e: MouseEvent, centerSpacing: { x: number, y: number }) => {
  return {
    x: e.offsetX - centerSpacing.x,
    y: e.offsetY - centerSpacing.y,
  }
}

/**
 * Checks if the coordinates are inside the rendered video and not in the empty space around the video
 */
const checkBoundsOfCoordinates = (coordinates: { x: number, y: number }, scaledVideoSize: { width: number, height: number }) => {
  return coordinates.x >= 0 &&
    coordinates.y >= 0 &&
    coordinates.x <= scaledVideoSize.width &&
    coordinates.y <= scaledVideoSize.height;
}

/**
 * Converts the local coordinates to normalized coordinates
 */
const normalizeCoordinates = (localCoordinates: { x: number, y: number }, size: { width: number, height: number }) => {
  return {
    x: localCoordinates.x / size.width,
    y: localCoordinates.y / size.height,
  }
}

export { getClickCoordinatesOnVideo }
