import React, { useState } from 'react';
import Modal from '@mui/joy/Modal';
import IconButton from '@mui/joy/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import { ProfileEditorTab } from '../container/ProfilePage/ProfileEditorTab';


const EditUserButton = ({ userId, onFinishedEditing }: { userId: string, onFinishedEditing?: Function }) => {
  const [open, setOpen] = useState<boolean>(false);

  return (
    <>
      <IconButton onClick={() => setOpen(true)} aria-label="delete">
        <EditIcon />
      </IconButton>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        sx={{ zIndex: 99999 }}
      >
        <ProfileEditorTab userId={userId} onFinishedEditing={() => { setOpen(false); onFinishedEditing && onFinishedEditing(); }} />
      </Modal>
    </>
  );
};


export { EditUserButton };
