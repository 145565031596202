import React from 'react';
import IconButton from '@mui/joy/IconButton';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';


// a component, that when pressed inserts code into the editor

const WebHookCodeInsertLink = ({ code, onInsert }: { code: string, onInsert: Function }) => {
  return (<IconButton size="sm" variant="plain" onClick={() => onInsert(code)}>
    <ContentCopyIcon />
  </IconButton>);
};

export { WebHookCodeInsertLink }
