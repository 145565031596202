import * as React from 'react';
import IconButton, { IconButtonProps } from '@mui/joy/IconButton';
import SupportIcon from '@mui/icons-material/Support';

export default function SupportButton(props: IconButtonProps) {
  const { onClick, sx, ...other } = props;

  return (
    <IconButton
      title="Support"
      size="sm"
      variant="outlined"
      color="neutral"
      {...other}
      sx={sx}
      onClick={(event) => {
        window.open("https://www.aunovis.de/", "_blank");
      }}
    >
      <SupportIcon />
    </IconButton>);
}
