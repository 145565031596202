import Button from '@mui/joy/Button';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Input from '@mui/joy/Input';
import Box from '@mui/joy/Box';
import Link from '@mui/joy/Link';
import { Link as RouterLink } from 'react-router-dom';
import Stack from '@mui/joy/Stack';
import Typography from '@mui/joy/Typography';
import FormHelperText from '@mui/joy/FormHelperText';
import Divider from '@mui/joy/Divider';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import ErrorOutlined from '@mui/icons-material/ErrorOutlined';
import { FormEvent, ReactElement, useState } from 'react';
import { Trans } from "@lingui/macro";

const RequestResetPasswordForm = () => {
  const [error, setError] = useState <ReactElement>(<></>);

  const doPasswordReset = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formElements = event.currentTarget.elements;
    const data = {
      email: (formElements.namedItem('email') as HTMLInputElement).value,
    };
    const answer = await fetch('/api/Identity/request-password-reset', {
      body: JSON.stringify(data),
      method: "POST",
      headers: {
        'Content-Type': 'application/json'
      }
    });
    if (answer.ok) {
      setError(<><InfoOutlined /><Trans>Please check your emails for a password reset link.</Trans ></>);
    } else {
      setError(<><ErrorOutlined /><Trans>There was an error while processing your request. Please try again later.</Trans ></>);
    }
  };

  const errorField =
    error ? <FormHelperText>
      {error}
    </FormHelperText> : null;

  return <>
    <Stack gap={4} sx={{ mb: 2 }}>
      <Stack gap={1}>
        <Typography level="h3"><Trans>Reset password</Trans></Typography>
        <Typography level="body-sm">
          <Trans>Do you want to login?</Trans>{' '}
          <Link component={RouterLink} to="/login" level="title-sm">
            <Trans>Click here to get back to the login form!</Trans>
          </Link>
        </Typography>
      </Stack>
    </Stack>
    <Divider
      sx={(theme) => ({
        [theme.getColorSchemeSelector('light')]: {
          color: { xs: '#FFF', md: 'text.tertiary' },
          '--Divider-lineColor': {
            xs: '#FFF',
            md: 'var(--joy-palette-divider)',
          },
        },
      })}
    >
    </Divider>
    <Stack gap={4} sx={{ mt: 2 }}>
      <form
        onSubmit={doPasswordReset}
      >
        <FormControl required>
          <FormLabel><Trans>Email adress</Trans></FormLabel>
          <Input type="email" name="email"  />
        </FormControl>
        {errorField}
        <Stack gap={4} sx={{ mt: 2 }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
          </Box>
          <Button name="submit" type="submit" fullWidth>
            <Trans>Reset password</Trans>
          </Button>
        </Stack>
      </form>
    </Stack>
  </>;
};

export { RequestResetPasswordForm };