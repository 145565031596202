import { Trans } from "@lingui/macro";
import { SfuSessionParticipantInformation } from "./ViewModels";
import Alert from '@mui/joy/Alert';

const RecordingControl = ({ sessionParticipantInformation }: { sessionParticipantInformation: SfuSessionParticipantInformation }) => {
  return (<>
    {sessionParticipantInformation.recording && <Alert color="danger" size="md"><Trans>This session is being recorded.</Trans></Alert>}
  </>)
}

export { RecordingControl }
