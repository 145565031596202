import * as React from 'react';
import Box from '@mui/joy/Box';
import { ToastRenderer } from './ToastRenderer';
import { useSearchParams } from "react-router-dom";

import { Sidebar } from './Sidebar';
import { Header } from './Header';

export const Layout = ({ children }: React.PropsWithChildren<{}>) => {
  const [searchParams] = useSearchParams();
  const isEmbedded = searchParams.get("embedded") === "true" ? true : false;


  return (
    <Box sx={{ display: 'flex', minHeight: '100dvh' }}>
      {isEmbedded || <Header />}
      {isEmbedded || <Sidebar />}
      <Box
        component="main"
        className="MainContent"
        sx={{
          px: {
            xs: 2,
            md: 6,
          },
          pt: { xs: 'calc(12px + var(--Header-height))', md: 3 },
          pb: { xs: 2, sm: 2, md: 3 },
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          minWidth: 0,
          height: '100dvh',
          gap: 1,
          overflow: 'auto',
        }}
      >
        {children}

        <ToastRenderer />
      </Box>
    </Box>
  );
}
