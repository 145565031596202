import React, { ReactElement, useState } from 'react';
import Button from '@mui/joy/Button';
import { Trans } from "@lingui/macro";
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import DialogTitle from '@mui/joy/DialogTitle';
import DialogContent from '@mui/joy/DialogContent';
import { SessionContext, SessionContextState } from '../../models/SessionContext';
import { Typography } from '@mui/joy';
import { useAuthenticatedFetch } from '../../stores/fetch';
import { DialogActions } from '@mui/joy';


const CloseSessionContextButton = ({ sessionContext, onFinished }: { sessionContext: SessionContext, onFinished?: Function }) => {
  const [open, setOpen] = useState<boolean>(false);
  const [error, setError] = useState<ReactElement>(<></>);
  const fetch = useAuthenticatedFetch();

  const onCloseClick = async () => {
    sessionContext.state = SessionContextState.Closed;
    const result = await fetch("/api/SessionContext", {
      method: "PATCH",
      body: JSON.stringify(sessionContext),
      headers: {
        'Content-Type': 'application/json'
      }
    });
    if (!result.ok) {
      if (result.text) {
        console.error(await result.text());
      } else {
        console.error(result);
      }
      setError(<Trans>Failed to update session context</Trans>);
      return;
    }
    const savedSessionContext = await result.json();
    if (savedSessionContext) {
      setError(<></>);
      setOpen(false);
      onFinished && onFinished();
    }
  }

  return (
    <>
      <Button color="danger" onClick={() => setOpen(true)}><Trans>Close context</Trans></Button>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
      >
        <ModalDialog>
          <DialogTitle><Trans>Lock and close session context</Trans></DialogTitle>
          <DialogContent><Trans>You can close the session context here.<br/>Please keep in mind, that you can't add new sessions to this context afterwards.</Trans></DialogContent>
          {error && <Typography>{error}</Typography>}
          <DialogActions>
            <Button color="danger" onClick={() => onCloseClick()}><Trans>Close session context</Trans></Button>
            <Button color="neutral" onClick={() => setOpen(false)}><Trans>Cancel</Trans></Button>
          </DialogActions>
        </ModalDialog>
      </Modal>
    </>
  );
};


export { CloseSessionContextButton };
