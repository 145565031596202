import Box from '@mui/joy/Box';
import IconButton, { iconButtonClasses } from '@mui/joy/IconButton';
import Button from '@mui/joy/Button';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { Link as RouterLink } from 'react-router-dom';
import Select from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import { Trans } from "@lingui/macro";
import { SortDirection } from './ListTypes';

const Pagination = ({ skip = 0, take = 20, totalCount, prefilter = "all", sort, sortDirection }: { skip: number, take: number, totalCount: number, prefilter: string, sort: string, sortDirection: SortDirection }) => {
  // generate page buttons based on skip, take, and totalCount
  // Show a maximum of 7 page buttons. Always show the first and the last page
  const pageButtons = [];
  const totalPages = Math.ceil(totalCount / take);
  const selectedPage = Math.min(skip / take + 1, totalPages);
  // show a maximum of 7 page buttons
  const maxPages = 7;
  let startPage = 1;
  let endPage = totalPages;
  if (totalPages > maxPages) {
    const offset = Math.floor(maxPages / 2);
    startPage = Math.max(1, selectedPage - offset);
    endPage = Math.min(totalPages, selectedPage + offset);
    
    if (startPage === 1) {
      endPage = maxPages;
    } else if (endPage === totalPages) {
      startPage = totalPages - maxPages + 1;
    }
  }
  for (let i = startPage; i <= endPage; i++) {
    pageButtons.push(i);
  }
  // show next button if there are more pages
  const showNext = skip + take < totalCount;

  // show previous button if there are previous pages
  const showPrevious = skip > 0;


  return (
    <Box
      className="Pagination-laptopUp"
      sx={{
        pt: 2,
        gap: 1,
        [`& .${iconButtonClasses.root}`]: { borderRadius: '50%' },
        display: {
          xs: 'none',
          sm: 'flex',
        },
      }}
    >
      <Button
        size="sm"
        variant="outlined"
        disabled={!showPrevious}
        to={`?skip=${skip - take}&take=${take}&prefilter=${prefilter}&sort=${sort}&sortDirection=${sortDirection}`}
        component={RouterLink}
        color="neutral"
        startDecorator={<KeyboardArrowLeftIcon />}
      >
        <Trans>Previous</Trans>
      </Button>
      <Box sx={{ flex: 1 }} />
      {pageButtons.map((page) => (
        <IconButton
          key={page}
          size="sm"
          color={page === selectedPage ? "primary" : "neutral"}
          variant={page === selectedPage ? "solid" : "outlined"}
          // link to current page with correct offset
          to={`?skip=${(page - 1) * take}&take=${take}&prefilter=${prefilter}&sort=${sort}&sortDirection=${sortDirection}`}
          component={RouterLink}
          style={{ textDecoration: "none" }}
        >
          {page}
        </IconButton>
      ))}
      <Box sx={{ flex: 1 }} />
      <Select defaultValue={take.toString()}>
        <Option to={`?skip=${skip}&take=10&prefilter=${prefilter}&sort=${sort}&sortDirection=${sortDirection}`}
          component={RouterLink} value="10">10</Option>
        <Option to={`?skip=${skip}&take=20&prefilter=${prefilter}&sort=${sort}&sortDirection=${sortDirection}`}
          component={RouterLink} value="20">20</Option>
        <Option to={`?skip=${skip}&take=50&prefilter=${prefilter}&sort=${sort}&sortDirection=${sortDirection}`}
          component={RouterLink} value="50">50</Option>
       </Select>
      <Button
        size="sm"
        variant="outlined"
        disabled={!showNext}
        to={`?skip=${skip + take}&take=${take}&prefilter=${prefilter}&sort=${sort}&sortDirection=${sortDirection}`}
        component={RouterLink}
        color="neutral"
        endDecorator={<KeyboardArrowRightIcon />}
      >
        <Trans>Next</Trans>
      </Button>
    </Box>
  );
}

export { Pagination };
