import { i18n } from "@lingui/core";
import { en, de, fr } from "make-plural/plurals";
import { useState, useEffect, useCallback } from "react";
import { Dictionary } from "./utils/Util";
import { useUserAccountStore } from "./stores/UserAccountStore";
import { Settings as luxonSettings } from 'luxon';
import { useSettingsStore } from "./stores/SettingsStore";

export const locales : Dictionary<string> = {
  "en": "English",
  "de": "German",
  "fr": "French",
};

// We're adding a configuration variable: 
// the default locale will be loaded when
// a visitor hits our site for the first
// time.
export const defaultLocale = "en";

i18n.loadLocaleData({
  en: { plurals: en },
  de: { plurals: de },
  fr: { plurals: fr },
});

i18n.load(defaultLocale, {});
i18n.activate(defaultLocale);

const loadMessages = async (locale : string) => {
  const { messages } = await import(`./locales/${locale}/messages`);

  // After we load the messages, we need to add them
  // to Lingui and tell it to activate the locale.
  i18n.load(locale, messages);
  i18n.activate(locale);
}

function useLocalization() {
  const [currentLocale, setCurrentLocale] = useState(defaultLocale);
  const [loading, setLoading] = useState(true);
  const setLanguage = useUserAccountStore((state) => state.setLanguage);
  const selectedLang = useUserAccountStore((state) => state.lang);
  const fetchSettings = useSettingsStore((state) => state.fetch);

  // We're using the useCallback hook to memoize the function
  const changeLocale = useCallback(async (locale: string) => {
    await setCurrentLocale(locale);
    await loadMessages(locale);
    await setLanguage(locale);
    luxonSettings.defaultLocale = locale;
  }, [setLanguage])

  useEffect(() => {
    const load = async () => {
      await fetchSettings();
      if (selectedLang) {
        await changeLocale(selectedLang);
      } else {
        // if no language is set, use the default language
        await changeLocale(defaultLocale);
      }
      await setLoading(false);
    };
    load();
  }, [changeLocale, fetchSettings, selectedLang]);

  return { currentLocale, changeLocale, loading };

}

export { useLocalization }