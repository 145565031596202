import { ReactElement, useEffect, useState } from 'react';
import Card from '@mui/joy/Card';
import Typography from '@mui/joy/Typography';
import CardActions from '@mui/joy/CardActions';
import CardOverflow from '@mui/joy/CardOverflow';
import { Trans } from "@lingui/macro";
import Divider from '@mui/joy/Divider';
import Stack from '@mui/joy/Stack';
import Box from '@mui/joy/Box';
import { useAuthenticatedFetch } from "../../stores/fetch";
import { InfoOutlined, ErrorOutlined } from '@mui/icons-material';
import Sheet from '@mui/joy/Sheet';
import Table from '@mui/joy/Table';
import { formatDate } from '../../utils/dateUtils';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import IconButton from '@mui/joy/IconButton';
import {AddApiTokenButton} from './AddApiTokenButton';
import { LoadingIndicator } from '../../components/LoadingIndicator';

interface Token {
  id: string;
  token: string;
  tokenName: string;
  expiryDate: string;
}


export const TokenTab = () => {
  const [tokens, setTokens] = useState<Token[] | null>(null);
  const [error, setError] = useState<ReactElement>(<></>);
  const fetch = useAuthenticatedFetch();


  const onTokenAdded = (token: Token) => {
    setError(<><InfoOutlined /><Trans> Token Added. Please copy it immediatly - It is only shown once.</Trans ></>);
    // add token to the list
    const newTokens = tokens ? [...tokens, token] : [token];
    setTokens(newTokens);
  }

  useEffect(() => {
    const loadTokensFromBackend = async () => {
      const result = await fetch("/api/Identity/mytokens", { method: "GET" });
      const tokens: Token[] = await result.json();
      setTokens(tokens);
    };
    loadTokensFromBackend();
  }, [fetch]);

  const deleteToken = async (id: string) => {
    const answer = await fetch(`/api/Identity/deletetoken`, {
      method: "DELETE",
      headers: {
        'Content-Type': 'application/json'
        },
        body: JSON.stringify({ id: id })
    });
    if (answer.ok) {
      const newTokens = tokens!.filter(token => token.id !== id);
      setTokens(newTokens);
      setError(<><InfoOutlined /><Trans>Token deleted.</Trans></>);
    } else {
      const errors = await answer.json();
      const errorText = errors.title;
      setError(<><ErrorOutlined />{errorText}</>);
    }
  };

  return (
    <Stack
      spacing={4}
      sx={{
        display: 'flex',
        maxWidth: '800px',
        mx: 'auto',
        px: { xs: 2, md: 6 },
        py: { xs: 2, md: 3 },
      }}
    >
      {tokens ?
        <Card>
          <Box sx={{ mb: 1 }}>
            <Typography level="title-md"><Trans>API Tokens</Trans></Typography>
            <Typography level="body-sm">
              <Trans>You can manage your API tokens here. These tokens are also used to onboard new head mounted devices like the hololens.</Trans>
            </Typography>
          </Box>
          <Divider />
          <Stack
            direction="row"
            spacing={3}
            sx={{ display: { xs: 'none', md: 'flex' }, my: 1 }}
          >
            <Stack spacing={2} sx={{ flexGrow: 1 }}>
              <Stack spacing={1}>
                <Sheet sx={{ height: 300, overflow: 'auto' }}>
                  <Table
                    aria-label="table with sticky header"
                    stickyHeader
                    stickyFooter
                    stripe="odd"
                    hoverRow
                  >
                    <thead>
                      <tr>
                        <th><Trans>Token name</Trans></th>
                        <th><Trans>Expiry date</Trans></th>
                        <th style={{ width: '40%' }}><Trans>Token</Trans></th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {tokens.map((row) => (
                        <tr key={row.id}>
                          <td>{row.tokenName}</td>
                          <td>{formatDate(row.expiryDate)}</td>
                          <td>{row.token}</td>
                          <td align="right"><IconButton onClick={() => deleteToken(row.id) }><DeleteForeverIcon /></IconButton></td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Sheet>
              </Stack>
            </Stack>
          </Stack>
          <CardOverflow sx={{ borderTop: '1px solid', borderColor: 'divider' }}>

            <p>{error}</p>
            <CardActions sx={{ alignSelf: 'flex-end', pt: 2 }}>
              <AddApiTokenButton onTokenAdded={(token : Token) => onTokenAdded(token) } />
            </CardActions>
          </CardOverflow>
        </Card>
        : <LoadingIndicator />
      }
    </Stack>
  );
};
