export enum SortDirection {
  Ascending = "Ascending",
  Descending = "Descending"
}

export enum SessionContextSortField {
  Id = "Id",
  CreatedAt = "CreatedAt",
  FinishedAt = "FinishedAt",
  Owner = "Owner",
  State = "State",
  ShortUrl = "ShortUrl",
  FriendlyName = "FriendlyName"
}

export enum SessionContextPrefilter {
  All = "all",
  Active = "active",
  Closed = "closed",
}

export enum SessionPrefilter {
  All = "all",
}

export enum SessionSortField {
  Id = "Id",
  CreatedAt = "CreatedAt",
  FinishedAt = "FinishedAt",
  Owner = "Owner",
  State = "State"
}

export enum DeviceSortField {
  Id = "Id",
  CreatedAt = "CreatedAt",
  Name = "Name",
}

export enum DevicePrefilter {
  All = "all"
}

export enum FilePrefilter {
  All = "all"
}

export enum FileSortField {
  Id = "Id",
  CreatedAt = "CreatedAt",
  FileName = "FileName",
}

export enum UserSortField {
  Id = "Id",
  FirstName = "FirstName",
  LastName = "LastName",
  Email = "Email",
}

export enum UserPrefilter {
  All = "all",
}

export enum WebHookSortField {
  Id = "Id",
  CreatedAt = "CreatedAt",
  Name = "Name",
  Url = "Url",
  Type = "Type",
  Method = "Method"
}

export enum WebHookPrefilter {
  All = "all"
}
