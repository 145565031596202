import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Typography from '@mui/joy/Typography';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import Stack from '@mui/joy/Stack';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Input from '@mui/joy/Input';
import { Breadcrumb } from "../../components/Breadcrumb";
import { msg, Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { ErrorOutlined } from '@mui/icons-material';
import { Device } from '../../models/Device';
import { useAuthenticatedFetch } from '../../stores/fetch';
import { AppInstaller } from '../../components/AppInstaller';

export function NewDevicePage() {
  const { _ } = useLingui();
  const [error, setError] = useState<JSX.Element | null>(null);
  const [device, setDevice] = useState<Device>({} as Device);
  const navigate = useNavigate();
  const fetch = useAuthenticatedFetch();

  const createDevice = async () => {
    const answer = await fetch("/api/Device", {
      method: "POST",
      body: JSON.stringify(device),
      headers: {
        'Content-Type': 'application/json'
      }
    });
    if (answer.ok) {
      navigate("/devicelist/all");
    } else {
      const errors = await answer.json();
      const errorText = errors.title;
      setError(<><ErrorOutlined />{errorText}</>);
    }
  };

  const updateDeviceModel = (field: keyof Device, value: string) => {
    if (!device) return;

    let newDevice = { ...device };
    newDevice[field] = value as any;
    setDevice(newDevice)
  };

  return (
    <Box sx={{ flex: 1, width: '100%' }}>

      <Breadcrumb crumbs={[{ title: _(msg`All devices`), link: "/devicelist/all" }, { title: _(msg`New device`)}]} />
      <Box
        sx={{
          position: 'sticky',
          top: { sm: -100, md: -110 },
          bgcolor: 'background.body',
          zIndex: 9995,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            mb: 1,
            gap: 1,
            flexDirection: { xs: 'column', sm: 'row' },
            alignItems: { xs: 'start', sm: 'center' },
            flexWrap: 'wrap',
            justifyContent: 'space-between',
          }}
        >
          <Typography level="h2" component="h1">
            <Trans>New device</Trans>
          </Typography>
        </Box>
        <Box>
          <AppInstaller />
        </Box>
        <Box>
          <Stack gap={4} sx={{ mt: 2 }}>
            <form>
              <FormControl required>
                <FormLabel><Trans>Device Name</Trans></FormLabel>
                <Input
                  onChange={e => updateDeviceModel("name", e.target.value)}
                  id="name"
                  value={device!.name}
                />
              </FormControl>
              <FormControl required>
                <FormLabel><Trans>Description</Trans></FormLabel>
                <Input
                  onChange={e => updateDeviceModel("description", e.target.value)}
                  id="description"
                  value={device!.description}
                />
              </FormControl>
              {error}
              <Stack gap={4} sx={{ mt: 2 }}>
                <Button onClick={() => createDevice()} fullWidth>
                  <Trans>Add device</Trans>
                </Button>
              </Stack>
            </form>
          </Stack>
        </Box>
      </Box>
    </Box>
  );
}
