/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from 'react';
import Box from '@mui/joy/Box';
import Link from '@mui/joy/Link';
import Table from '@mui/joy/Table';
import Sheet from '@mui/joy/Sheet';
import Checkbox from '@mui/joy/Checkbox';
import Typography from '@mui/joy/Typography';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Trans } from "@lingui/macro";
import { Pagination } from './Pagination';
import { useSearchParams } from "react-router-dom";
import { Link as RouterLink } from "react-router-dom";
import { formatDateWithAgo } from '../utils/dateUtils';
import { Session, SessionState } from '../models/Session';
import { SessionPrefilter, SessionSortField, SortDirection } from './ListTypes';
import { SessionStateChip } from './SessionStateChip';
import { ParticipantList } from './ParticipantList';

import { IconButton } from '@mui/joy';
import PreviewIcon from '@mui/icons-material/Preview';
import GroupIcon from '@mui/icons-material/Group';

export function SessionTable({ sessions = [], skip = 0, take = 20, totalCount, sort, sortDirection = SortDirection.Ascending, prefilter }: { sessions: Session[], skip: number, take: number, totalCount: number, sort: SessionSortField, sortDirection: SortDirection, prefilter: SessionPrefilter }) {
  const [selected, setSelected] = React.useState<string[]>([]);
  const [searchParams, setSearchParams] = useSearchParams();

  const setSort = (sort: SessionSortField) => {
    searchParams.set("sort", sort);
    setSearchParams(searchParams);
  }

  const setSortDirection = (sortDirection: SortDirection) => {
    searchParams.set("sortDirection", sortDirection);
    setSearchParams(searchParams);
  }

  const SortLink = ({ activeSort, activeSortDirection, columnName, children }: React.PropsWithChildren<{ activeSort: SessionSortField, activeSortDirection: SortDirection, columnName: SessionSortField }>) => {
    return (<Link
      underline="none"
      color={activeSort === columnName ? "primary" : "neutral"}
      component="button"
      onClick={() => {
        setSort(columnName);
        setSortDirection(activeSortDirection === SortDirection.Ascending ? SortDirection.Descending : SortDirection.Ascending);
      }
      }
      fontWeight="lg"
      endDecorator={activeSort === columnName ?<ArrowDropDownIcon /> : <></>}
      sx={{
        '& svg': {
          transition: '0.2s',
          transform:
            sortDirection === 'Descending' ? 'rotate(0deg)' : 'rotate(180deg)',
        },
      }}
    >
      {children}
    </Link>);
}
  return (
    <React.Fragment>
      <Sheet
        className="OrderTableContainer"
        variant="outlined"
        sx={{
          width: '100%',
          borderRadius: 'sm',
          flexShrink: 1,
          overflow: 'auto',
          minHeight: 0,
        }}
      >
        <Table
          aria-labelledby="tableTitle"
          stickyHeader
          hoverRow
          sx={{
            '--TableCell-headBackground': 'var(--joy-palette-background-level1)',
            '--Table-headerUnderlineThickness': '1px',
            '--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
            '--TableCell-paddingY': '4px',
            '--TableCell-paddingX': '8px',
          }}
        >
          <thead>
            <tr>
              <th style={{ width: 48, textAlign: 'center', padding: '12px 6px' }}>
                <Checkbox
                  size="sm"
                  indeterminate={
                    selected.length > 0 && selected.length !== sessions.length
                  }
                  checked={selected.length === sessions.length}
                  onChange={(event) => {
                    setSelected(
                      event.target.checked ? sessions.map((row) => row.id) : [],
                    );
                  }}
                  color={
                    selected.length > 0 || selected.length === sessions.length
                      ? 'primary'
                      : undefined
                  }
                  sx={{ verticalAlign: 'text-bottom' }}
                />
              </th>
              <th style={{ width: 140, padding: '12px 6px' }}>
                <SortLink activeSort={sort} activeSortDirection={sortDirection} columnName={SessionSortField.CreatedAt}>
                  <Trans>Created At</Trans>
                </SortLink>
              </th>
              <th style={{ width: 140, padding: '12px 6px' }}>
                <SortLink activeSort={sort} activeSortDirection={sortDirection} columnName={SessionSortField.FinishedAt}>
                  <Trans>Finished At</Trans>
                </SortLink>
              </th>
              <th style={{ width: 140, padding: '12px 6px' }}>
                <Trans>Participants</Trans>
              </th>
              <th style={{ width: 140, padding: '12px 6px' }}>
                <SortLink activeSort={sort} activeSortDirection={sortDirection} columnName={SessionSortField.State}>
                  <Trans>State</Trans>
                </SortLink>
              </th>
              <th style={{ width: 140, padding: '12px 6px' }}> </th>
            </tr>
          </thead>
          <tbody>
            {sessions.map((row) => (
              <tr key={row.id}>
                <td style={{ textAlign: 'center', width: 120 }}>
                  <Checkbox
                    size="sm"
                    checked={selected.includes(row.id)}
                    color={selected.includes(row.id) ? 'primary' : undefined}
                    onChange={(event) => {
                      setSelected((ids) =>
                        event.target.checked
                          ? ids.concat(row.id)
                          : ids.filter((itemId) => itemId !== row.id),
                      );
                    }}
                    slotProps={{ checkbox: { sx: { textAlign: 'left' } } }}
                    sx={{ verticalAlign: 'text-bottom' }}
                  />
                </td>
                <td>
                  <Typography level="body-xs">{formatDateWithAgo(row.createdAt)}</Typography>
                </td>
                <td>
                  <Typography level="body-xs">{formatDateWithAgo(row.finishedAt)}</Typography>
                </td>
                <td>
                  <Typography level="body-xs"><ParticipantList participants={row.participants} /></Typography>
                </td>
                <td>
                  <SessionStateChip state={row.state} />
                </td>
                <td>
                  <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                    {(row.state === SessionState.Created || row.state === SessionState.Started) && row.shortURL ?
                      <IconButton component={RouterLink} to={`/livesession/${row.shortURL}`} aria-label="join">
                        <GroupIcon />
                      </IconButton>
                      : <IconButton component={RouterLink} to={`/session/${row.id}`} aria-label="view">
                        <PreviewIcon />
                      </IconButton>
                    }
                  </Box>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Sheet>
      <Pagination take={take} skip={skip} totalCount={totalCount} prefilter={prefilter}  sort={sort} sortDirection={sortDirection} />
    </React.Fragment>
  );
}
