import React from 'react';
import Accordion from '@mui/joy/Accordion';
import AccordionDetails from '@mui/joy/AccordionDetails';
import AccordionSummary from '@mui/joy/AccordionSummary';
import AccordionGroup from '@mui/joy/AccordionGroup';
import Table from '@mui/joy/Table';
import { WebHookType } from '../../models/WebHook';

import {Trans } from '@lingui/macro';
import { WebHookCodeInsertLink } from './WebHookCodeInsertLink';
import { Box } from '@mui/joy';

const SessionPlaceholderLegend = ({ onInsert, expanded }: { onInsert: Function, expanded: boolean}) => {
  return (
    <Accordion defaultExpanded={expanded}>
      <AccordionSummary><Trans>Session</Trans></AccordionSummary>
      <AccordionDetails>
        <Trans>Represents the session that the webhook is triggered for.</Trans>
        <Table size={'sm'}>
          <thead>
            <tr>
              <th style={{ width: '30%' }}><Trans>Property</Trans></th>
              <th><Trans>Description</Trans></th>
              <th style={{ width: '20%' }}><Trans>Insert</Trans></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>SessionId</td>
              <td><Trans>The Session's id</Trans></td>
              <td><WebHookCodeInsertLink onInsert={onInsert} code='@Model["Session"]["SessionId"]' /></td>
            </tr>
            <tr>
              <td>SessionContextId</td>
              <td><Trans>The Session's Context's' id</Trans></td>
              <td><WebHookCodeInsertLink onInsert={onInsert} code='@Model["Session"]["SessionContextId"]' /></td>
            </tr>
            <tr>
              <td>State</td>
              <td><Trans>The State of the Session. (Enum)</Trans></td>
              <td><WebHookCodeInsertLink onInsert={onInsert} code='@Model["Session"]["State"]' /></td>
            </tr>
            <tr>
              <td>CreatedAt</td>
              <td><Trans>The date of the Session's creation</Trans></td>
              <td><WebHookCodeInsertLink onInsert={onInsert} code='@Model["Session"]["CreatedAt"]' /></td>
            </tr>
            <tr>
              <td>FinishedAt</td>
              <td><Trans>The date of the Session's completion</Trans></td>
              <td><WebHookCodeInsertLink onInsert={onInsert} code='@Model["Session"]["FinishedAt"]' /></td>
            </tr>
            <tr>
              <td>OwnerId</td>
              <td><Trans>The UserID of the owner</Trans></td>
              <td><WebHookCodeInsertLink onInsert={onInsert} code='@Model["Session"]["OwnerId"]' /></td>
            </tr>
            <tr>
              <td>OrganisationId</td>
              <td><Trans>The OrganisationId of the corresponding organisation</Trans></td>
              <td><WebHookCodeInsertLink onInsert={onInsert} code='@Model["Session"]["OrganisationId"]' /></td>
            </tr>
          </tbody>
        </Table>
        
      </AccordionDetails>
    </Accordion>
  );
};

const SessionContextPlaceholderLegend = ({ onInsert, expanded }: { onInsert: Function, expanded: boolean }) => {
  return (
    <Accordion defaultExpanded={expanded}>
      <AccordionSummary><Trans>Session Context</Trans></AccordionSummary>
      <AccordionDetails>
        <Trans>Represents the session context that the webhook is triggered for.</Trans>
        <Table size={'sm'}>
          <thead>
            <tr>
              <th style={{ width: '30%' }}><Trans>Property</Trans></th>
              <th><Trans>Description</Trans></th>
              <th style={{ width: '20%' }}><Trans>Insert</Trans></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>SessionContextId</td>
              <td><Trans>The SessionContext's id</Trans></td>
              <td><WebHookCodeInsertLink onInsert={onInsert} code='@Model["SessionContext"]["SessionContextId"]' /></td>
            </tr>
            <tr>
              <td>ShortURL</td>
              <td><Trans>The Session Context's URL Code to join</Trans></td>
              <td><WebHookCodeInsertLink onInsert={onInsert} code='@Model["SessionContext"]["ShortURL"]' /></td>
            </tr>
            <tr>
              <td>State</td>
              <td><Trans>The State of the Session Context. (Enum)</Trans></td>
              <td><WebHookCodeInsertLink onInsert={onInsert} code='@Model["SessionContext"]["State"]' /></td>
            </tr>
            <tr>
              <td>CreatedAt</td>
              <td><Trans>The date of the Session Context's creation</Trans></td>
              <td><WebHookCodeInsertLink onInsert={onInsert} code='@Model["SessionContext"]["CreatedAt"]' /></td>
            </tr>
            <tr>
              <td>FinishedAt</td>
              <td><Trans>The date of the Session Context's completion</Trans></td>
              <td><WebHookCodeInsertLink onInsert={onInsert} code='@Model["SessionContext"]["FinishedAt"]' /></td>
            </tr>
            <tr>
              <td>OwnerId</td>
              <td><Trans>The UserID of the owner</Trans></td>
              <td><WebHookCodeInsertLink onInsert={onInsert} code='@Model["SessionContext"]["OwnerId"]' /></td>
            </tr>
            <tr>
              <td>OrganisationId</td>
              <td><Trans>The OrganisationId of the corresponding organisation</Trans></td>
              <td><WebHookCodeInsertLink onInsert={onInsert} code='@Model["SessionContext"]["OrganisationId"]' /></td>
            </tr>
          </tbody>
        </Table>

      </AccordionDetails>
    </Accordion>
  );
};

const ParticipantPlaceholderLegend = ({ onInsert, expanded }: { onInsert: Function, expanded: boolean }) => {
  return (
    <Accordion defaultExpanded={expanded }>
      <AccordionSummary><Trans>Participant</Trans></AccordionSummary>
      <AccordionDetails>
        <Trans>Represents the participant of a session the webhook is triggered for.</Trans>
        <Table size={'sm'}>
          <thead>
            <tr>
              <th style={{ width: '30%' }}><Trans>Property</Trans></th>
              <th><Trans>Description</Trans></th>
              <th style={{ width: '20%' }}><Trans>Insert</Trans></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Id</td>
              <td><Trans>The Participant's id</Trans></td>
              <td><WebHookCodeInsertLink onInsert={onInsert} code='@Model["Participant"]["Id"]' /></td>
            </tr>
            <tr>
              <td>CreatedAt</td>
              <td><Trans>The time, the participant record was created</Trans></td>
              <td><WebHookCodeInsertLink onInsert={onInsert} code='@Model["Participant"]["CreatedAt"]' /></td>
            </tr>
            <tr>
              <td>LeftAt</td>
              <td><Trans>The time, the participant left</Trans></td>
              <td><WebHookCodeInsertLink onInsert={onInsert} code='@Model["Session"]["LeftAt"]' /></td>
            </tr>
            <tr>
              <td>CreatedAt</td>
              <td><Trans>The date of the Session's creation</Trans></td>
              <td><WebHookCodeInsertLink onInsert={onInsert} code='@Model["Session"]["CreatedAt"]' /></td>
            </tr>
            <tr>
              <td>SessionId</td>
              <td><Trans>The id of the session</Trans></td>
              <td><WebHookCodeInsertLink onInsert={onInsert} code='@Model["Session"]["SessionId"]' /></td>
            </tr>
            <tr>
              <td>UserId</td>
              <td><Trans>The UserId of the participant (only for user participants)</Trans></td>
              <td><WebHookCodeInsertLink onInsert={onInsert} code='@Model["Session"]["UserId"]' /></td>
            </tr>
            <tr>
              <td>ConnectionId</td>
              <td><Trans>The Realtime ConnectionId of the participant</Trans></td>
              <td><WebHookCodeInsertLink onInsert={onInsert} code='@Model["Session"]["ConnectionId"]' /></td>
            </tr>
            <tr>
              <td>DeviceId</td>
              <td><Trans>The DeviceId of the participant (only for device participants)</Trans></td>
              <td><WebHookCodeInsertLink onInsert={onInsert} code='@Model["Session"]["DeviceId"]' /></td>
            </tr>
          </tbody>
        </Table>
      </AccordionDetails>
    </Accordion>
  );
};

const AdditionalParametersPlaceholderLegend = ({ onInsert, expanded }: { onInsert: Function, expanded: boolean }) => {
  return (
    <Accordion defaultExpanded={expanded}>
      <AccordionSummary><Trans>Additional Parameters</Trans></AccordionSummary>
      <AccordionDetails>
        <Trans>Additional Parameters for authorisation purposes</Trans>
        <Table size={'sm'}>
          <thead>
            <tr>
              <th style={{ width: '30%' }}><Trans>Property</Trans></th>
              <th><Trans>Description</Trans></th>
              <th style={{ width: '20%' }}><Trans>Insert</Trans></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>OTP</td>
              <td><Trans>This key gets extracted from the URL (?otp=xxxx)</Trans></td>
              <td><WebHookCodeInsertLink onInsert={onInsert} code='@Model["AdditionalParameters"]["OTP"]' /></td>
            </tr>
          </tbody>
        </Table>
      </AccordionDetails>
    </Accordion>
  );
};

const BeforeParticipantJoinsSessionLegend = () => {
  return (
    <Box sx={{padding: 2} }>
      <Trans>This Webhook expects a specific status code from the remote server. A HTTP status code {'>'} 200 and {'<'} 300 is used, if the user is allowed to join the session. If you return an other Status code, the user is rejected.</Trans>
    </Box>
  );
};


export const PlaceholderLegend = ({ webHookType, onInsert }: { webHookType: WebHookType, onInsert: Function }) => {

  switch (webHookType) {
    case WebHookType.SessionCreated:
      return (<AccordionGroup>
        <SessionPlaceholderLegend onInsert={onInsert} expanded={true} />
        <SessionContextPlaceholderLegend onInsert={onInsert} expanded={false} />
      </AccordionGroup>);
    case WebHookType.SessionEnded:
      return (<AccordionGroup>
        <SessionPlaceholderLegend onInsert={onInsert} expanded={true} />
        <SessionContextPlaceholderLegend onInsert={onInsert} expanded={false} />
      </AccordionGroup>);
    case WebHookType.SessionContextCreated:
      return (<AccordionGroup>
        <SessionContextPlaceholderLegend onInsert={onInsert} expanded={true} />
      </AccordionGroup>);
    case WebHookType.SessionContextClosed:
      return (<AccordionGroup>
        <SessionContextPlaceholderLegend onInsert={onInsert} expanded={true} />
      </AccordionGroup>);
    case WebHookType.ParticipantJoinedSession:
      return (<AccordionGroup>
        <SessionPlaceholderLegend onInsert={onInsert} expanded={true} />
        <ParticipantPlaceholderLegend onInsert={onInsert} expanded={false} />
        <SessionContextPlaceholderLegend onInsert={onInsert} expanded={false} />
      </AccordionGroup>);
    case WebHookType.ParticipantLeftSession:
      return (<AccordionGroup>
        <SessionPlaceholderLegend onInsert={onInsert} expanded={true} />
        <ParticipantPlaceholderLegend onInsert={onInsert} expanded={false} />
        <SessionContextPlaceholderLegend onInsert={onInsert} expanded={false} />
      </AccordionGroup>);
    case WebHookType.BeforeParticipantJoinsSession:
      return (<AccordionGroup>
        <SessionPlaceholderLegend onInsert={onInsert} expanded={true} />
        <ParticipantPlaceholderLegend onInsert={onInsert} expanded={false} />
        <AdditionalParametersPlaceholderLegend onInsert={onInsert} expanded={false} />
        <BeforeParticipantJoinsSessionLegend />
      </AccordionGroup>);
    default:
      return <div>Unknown webHookType</div>;

  };
};
