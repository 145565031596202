
import Breadcrumbs from '@mui/joy/Breadcrumbs';
import Link from '@mui/joy/Link';

import Typography from '@mui/joy/Typography';
import Box from '@mui/joy/Box';
// icons
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import { Link as RouterLink } from 'react-router-dom';

interface Crumb {
  title: string;
  link?: string;
}

const Breadcrumb = ({ crumbs }: { crumbs: Array<Crumb> }) => {

  const content = crumbs.map((crumb, index) => {
    if (index === crumbs.length - 1) return (<Typography key={crumb.title + crumb.link} color="primary" fontWeight={500} fontSize={12}>{crumb.title}</Typography>)

    return (<Link
      underline="hover"
      color="neutral"
      // @ts-ignore typescript gets confused when using props of component (RouterLink)
      to={crumb.link}
      fontSize={12}
      key={crumb.title + crumb.link}
      fontWeight={500}
      component={RouterLink}
    >
      {crumb.title}
    </Link>);
  });

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Breadcrumbs
        size="sm"
        aria-label="breadcrumbs"
        separator={<ChevronRightRoundedIcon fontSize="small" />}
        sx={{ pl: 0 }}
      >
        <Link
          underline="none"
          color="neutral"
          key="home"
          to="/"
          aria-label="Home"
          component={RouterLink}
        >
          <HomeRoundedIcon />
        </Link>
        {content}
      </Breadcrumbs>
    </Box>
  );
};

export { Breadcrumb };