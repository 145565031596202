import Button from '@mui/joy/Button';
import Checkbox from '@mui/joy/Checkbox';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Input from '@mui/joy/Input';
import Box from '@mui/joy/Box';
import Link from '@mui/joy/Link';
import { Link as RouterLink, useSearchParams } from 'react-router-dom';
import Stack from '@mui/joy/Stack';
import Typography from '@mui/joy/Typography';
import FormHelperText from '@mui/joy/FormHelperText';
import Divider from '@mui/joy/Divider';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import { FormEvent, ReactElement, useState } from 'react';
import { useUserAccountStore } from '../../stores/UserAccountStore';
import { useNavigate } from 'react-router-dom';
import { Trans, msg } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { useSettingsStore } from '../../stores/SettingsStore';

const LoginForm = () => {
  const [error, setError] = useState<ReactElement | null>(null);
  const login = useUserAccountStore((state) => state.login)
  const navigate = useNavigate();
  const { _ } = useLingui();
  const getSetting = useSettingsStore((state) => state.getSetting);
  const allowRegistration = getSetting('AllowRegistration');
  const allowPasswordReset = getSetting('AllowPasswordReset');

  const [searchParams] = useSearchParams();
  const originalRequestedPath = searchParams.get("path");


  const doLogin = async (event: FormEvent<HTMLFormElement>) => {
    setError(null);
    event.preventDefault();
    const formElements = event.currentTarget.elements;
    const data = {
      email: (formElements.namedItem('email') as HTMLInputElement).value,
      password: (formElements.namedItem('password') as HTMLInputElement).value,
      //persistent: formElements.persistent.checked,
    };
    const answer = await fetch('/api/Identity/login', {
      body: JSON.stringify(data),
      method: "POST",
      headers: {
        'Content-Type': 'application/json'
      }
    });
    if (answer.ok) {
      login(await answer.text());
      if (originalRequestedPath) {
        // check if originalRequestedPath starts with a / - if not add it
        if (!originalRequestedPath.startsWith('/')) {
          navigate(`/${originalRequestedPath}`);
        } else {
          navigate(originalRequestedPath);
        }
      } else {
        navigate(`/`);
      }
    } else {
      setError(<Trans>Login failed</Trans >);
    }
  };

  const errorField =
    (error !== null) ? <FormHelperText>
      <InfoOutlined />
      {error}
    </FormHelperText> : null;

  return<>
    <Stack gap={4} sx={{ mb: 2 }}>
      <Stack gap={1}>
        <Typography level="h3"><Trans>Sign in</Trans></Typography>
        <Typography level="body-sm">
          <Trans>New to Q!Remote?</Trans>{' '}
          {allowRegistration === "true" &&
            <Link to="/login/register" component={RouterLink} level="title-sm">
              <Trans>Sign up!</Trans>
            </Link>
          }
        </Typography>
      </Stack>
    </Stack>
    <Divider
      sx={(theme) => ({
        [theme.getColorSchemeSelector('light')]: {
          color: { xs: '#FFF', md: 'text.tertiary' },
          '--Divider-lineColor': {
            xs: '#FFF',
            md: 'var(--joy-palette-divider)',
          },
        },
      })}
    >
    or
    </Divider>
    <Stack gap={4} sx={{ mt: 2 }}>
      <form
        onSubmit={doLogin}
      >
        <FormControl required>
          <FormLabel><Trans>Email</Trans></FormLabel>
          <Input type="email" name="email" />
        </FormControl>
        <FormControl required>
        <FormLabel><Trans>Password</Trans></FormLabel>
          <Input type="password" name="password" />
          {errorField}
        </FormControl>
        <Stack gap={4} sx={{ mt: 2 }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Checkbox size="sm" label={_(msg`Remember me`)} name="persistent" />
            {allowPasswordReset === "true" &&
              <Link to="/login/request-reset-password" component={RouterLink} level="title-sm">
                <Trans>Forgot your password?</Trans>
              </Link>
            }
          </Box>
          <Button type="submit" fullWidth>
            <Trans>Sign in</Trans>
          </Button>
        </Stack>
      </form>
    </Stack>
  </>;
};

export { LoginForm };
