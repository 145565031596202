import { useEffect, useRef, useState} from 'react';
import Box from '@mui/joy/Box';
import Sheet from '@mui/joy/Sheet';
import Stack from '@mui/joy/Stack';
import AvatarWithStatus from './AvatarWithStatus';
import ChatBubble from './ChatBubble';
import MessageInput from './MessageInput';
import { ChatMessage } from '../../models/ChatMessage';
import { SfuSessionParticipantInformation } from '../../container/LiveSessionPage/ViewModels';
import { SxProps } from '@mui/material';

export default function MessagesPane({ onSendMessage, messages, currentUserInformation, sx }: { onSendMessage: (message: string) => void, messages: ChatMessage[], currentUserInformation: SfuSessionParticipantInformation | string, sx?: SxProps }) {
  const [textAreaValue, setTextAreaValue] = useState('');
  const messagesEndRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  });

  const isOwnMessage = (message: ChatMessage) => {
    if (typeof currentUserInformation === 'string') {
      return message.userId === currentUserInformation;
    } else {
      if (message.userId) {
        return message.userId === currentUserInformation.userId;
      } else if (message.deviceId) {
        return message.deviceId === currentUserInformation.deviceId;
      } else {
        return message.connectionId === currentUserInformation.connectionId;
      }
    }
  };

  return (
    <Sheet
      sx={{
        height: { xs: 'calc(30dvh - var(--Header-height))', lg: '30dvh' },
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: 'background.level1',
        ...sx
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flex: 1,
          minHeight: 0,
          px: 2,
          py: 3,
          overflowY: 'scroll',
          flexDirection: 'column-reverse',
        }}
      >
        <Stack spacing={2} justifyContent="flex-end">
          {messages.map((message: ChatMessage, index: number) => {
            const isYou = isOwnMessage(message);
            return (
              <Stack
                key={index}
                direction="row"
                spacing={2}
                flexDirection={isYou ? 'row-reverse' : 'row'}
              >
                {!isYou && (
                  <AvatarWithStatus
                    online={"" !== message.userName}
                    src={`/api/Identity/profilepicture/${message.userId}`}
                  />
                )}
                <ChatBubble variant={isYou ? 'sent' : 'received'} message={message} />
              </Stack>
            );
          })}
          <div style={{ float: "left", clear: "both" }}
            ref={messagesEndRef}>
          </div>
        </Stack>
      </Box>
      <MessageInput
        textAreaValue={textAreaValue}
        setTextAreaValue={setTextAreaValue}
        onSubmit={() => {
          onSendMessage(textAreaValue);
        }}
      />
    </Sheet>
  );
}
