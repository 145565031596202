import React from "react";
import Snackbar from '@mui/joy/Snackbar';
import { useToastStore } from "../stores/ToastStore";
import { Typography } from "@mui/joy";

const ToastRenderer = () => {
  const messages = useToastStore(store => store.messages);

  return (<>
    {messages.map((message, index) =>
      <Snackbar
        variant="soft"
        color={message.color || "danger"}
        open={true}
        sx={{ height: "80px", bottom: index * 100 + 10, transitionProperty: "bottom", transitionDuration: "0.5", flexDirection: "column", display: "flex" }}
      >
        <Typography level="title-lg">
          {message.title}
        </Typography>
        <Typography level="body-sm">
          {message.content}
        </Typography>
      </Snackbar>
    )}
  </>);
}

export { ToastRenderer }
