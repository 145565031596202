import ChipDelete from '@mui/joy/ChipDelete';
import QrCode2Icon from '@mui/icons-material/QrCode2';
import { QRCodeModal } from './QRCodeModal';
import { ReactNode } from 'react';

const QRCodeChipDecorator = ({ value, title }: { value: string, title?: ReactNode }) => {
  return (
    <QRCodeModal title={title} value={value}><ChipDelete sx={{ cursor: "pointer", verticalAlign: "middle" }} color="primary"><QrCode2Icon /></ChipDelete></QRCodeModal>
  );
}

export { QRCodeChipDecorator };
