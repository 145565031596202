import { Button, Card, Typography } from "@mui/joy";
import { CloseSessionContextButton } from "./CloseSessionContextButton";
import { SessionContext, SessionContextState } from "../../models/SessionContext";
import { SessionContextStateChip } from "../../components/SessionContextStateChip";
import { formatDate } from "../../utils/dateUtils";
import { Link as RouterLink } from 'react-router-dom';
import { Trans } from "@lingui/macro";

const SessionContextSidebar = ({ sessionContext }: { sessionContext: SessionContext }) => {

  return (
    <Card>
      <Typography level="title-lg"><Trans>Session context information</Trans></Typography>
      <Typography level="body-sm"><Trans>Context ID: {sessionContext.id}</Trans></Typography>
      <Typography level="body-sm"><Trans>State: <SessionContextStateChip state={sessionContext.state} /></Trans></Typography>
      <Typography level="body-sm"><Trans>ShortURL: {sessionContext.shortURL}</Trans></Typography>
      <Typography level="body-sm"><Trans>Created at: {formatDate(sessionContext.createdAt)}</Trans></Typography>
      <Typography level="body-sm"><Trans>Organisation: {sessionContext.organisationName}</Trans></Typography>
      {(sessionContext.state !== SessionContextState.Closed) && <>
        <CloseSessionContextButton sessionContext={sessionContext} onFinished={() => { }} />
        <Button component={RouterLink} to={`/livesession/${sessionContext.shortURL}`}><Trans>Enter session</Trans></Button>
      </>
      }
    </Card>
  );
};

export { SessionContextSidebar };
