export enum SessionState {
  Created = "Created",
  Started = "Started",
  Processing = "Processing",
  Finished = "Finished",
}

export interface SessionParticipant {
  id: string;
  createdAt: string;
  leftAt: string;
  userId: string;
  userName: string;
  deviceId: string;
  deviceName: string;
  avatarUrl: string;
}

export interface Session {
  id: string;
  ownerId: string;
  createdAt: string;
  finishedAt: string;
  state: SessionState;
  ownerName: string;
  organisationName: string;
  organisationId: string;
  participants: SessionParticipant[];
  shortURL: string;

}
