import { create } from 'zustand'
import { persist } from 'zustand/middleware'

interface UserAccountStoreState {
  token: string | null;
  lang: string;
  login: (token: string) => void;
  setLanguage: (lang: string) => void;
  logout: () => void;
}

// curried version https://stackoverflow.com/questions/69814018/zustand-typescript-persist-how-to-type-store
const useUserAccountStore = create<UserAccountStoreState>()(
  persist(
    (set) => ({
      token: null,
      lang: "en",
      login: (token) => set((state) => ({ token })),
      setLanguage: (lang) => set((state) => ({ lang })),
      logout: () => {
        set({ token: null });
        window.location.replace("/login?path=" + encodeURIComponent(window.location.pathname + window.location.search));
      },
    }
  ),
  { name: 'user-account-storage'}
));

export { useUserAccountStore }
