import { Trans } from "@lingui/macro";
import { Card, Typography } from "@mui/joy";
import { File } from "../models/File";

const MediaViewer = ({ file }: { file: File }) => {

  const createViewer = () => {
    switch (file.contentType) {
      // video player
      case 'video/mp4':
      case 'video/quicktime':
      case 'video/webm':
      case 'video/ogg':
      case 'audio/mpeg':
      case 'audio/ogg':
      case 'audio/wav':
        return <video src={`/api/File/${file?.id}/content`} controls autoPlay={true} style={{ width: "100%", maxHeight: "75vh" }} />;
      case 'image/jpeg':
      case 'image/png':
      case 'image/gif':
        return <img alt={file.fileName} src={`/api/File/${file?.id}/content`} />;
      default:
        return <Typography level="body-md">No viewer available for this file type</Typography>;
    }
  };

  return (
    <Card>
      <Typography level="title-lg"><Trans>Session player</Trans></Typography>
      {createViewer()}
    </Card>
  )
};

export { MediaViewer }
