import React, { useState } from 'react';
import IconButton from '@mui/joy/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import DialogTitle from '@mui/joy/DialogTitle';
import DialogContent from '@mui/joy/DialogContent';
import { Trans } from "@lingui/macro";
import Button from '@mui/joy/Button';
import DialogActions from '@mui/joy/DialogActions';

const ConfirmDeleteButton = ({ onDeleteClick }: { onDeleteClick: Function }) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <IconButton onClick={() => setOpen(true)} aria-label="delete">
        <DeleteIcon />
      </IconButton>
      <Modal
        open={open}
        sx={{ zIndex: 99999 } }
        onClose={() => setOpen(false)}
      >
        <ModalDialog>
          <DialogTitle><Trans>Are you sure, you want to delete this item?</Trans></DialogTitle>
          <DialogContent><Trans>You can't undo this.</Trans></DialogContent>
          <DialogActions>
            <Button color="danger" onClick={() => { onDeleteClick(); setOpen(false); }}><Trans>Delete</Trans></Button>
            <Button color="neutral" onClick={() => setOpen(false)}><Trans>Cancel</Trans></Button>
          </DialogActions>
        </ModalDialog>
      </Modal>
    </>
  );
}

export { ConfirmDeleteButton };
