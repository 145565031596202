import { useParams } from "react-router-dom";
import { useMemo, useEffect, useState } from "react";
import Grid from '@mui/joy/Grid';
import Card from '@mui/joy/Card';
import Typography from '@mui/joy/Typography';
import Button from '@mui/joy/Button';
import CardContent from '@mui/joy/CardContent';
import Box from '@mui/joy/Box';
import { Breadcrumb } from "../../components/Breadcrumb";
import { msg } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Trans } from "@lingui/macro";
import { useSearchParams } from "react-router-dom";
import { useAuthenticatedFetch } from "../../stores/fetch";
import { LiveSessionPage } from "./LiveSessionPage";
import { SessionContext, SessionContextRecordingMode } from "../../models/SessionContext";
import QrCode2Icon from '@mui/icons-material/QrCode2';
import { QRCodeModal } from "../../components/QRCodeModal";

const SessionInteractionWrapper = () => {
  const { _ } = useLingui();
  const [searchParams] = useSearchParams();
  const isEmbedded = searchParams.get("embedded") === "true" ? true : false;
  const fetch = useAuthenticatedFetch();
  const [sessionContext, setSessionContext] = useState<SessionContext | null>(null);

  let [showUserInteractionPanel, setShowUserInteractionPanel] = useState(true);
  let [cardMessage, setCardMessage] = useState(_(msg`Please click the button below to join the session.`));
  let [cardTitle, setCardTitle] = useState(_(msg`Join session`));
  let [buttonCaption, setButtonCaption] = useState(_(msg`Click here to join the session`));
  let [buttonVisible, setButtonVisible] = useState(true);
  // @ts-ignore
  let hasBeenActive = useMemo(() => navigator.userActivation?.hasBeenActive, []);

  let { id } = useParams();
  let content;

  useEffect(() => {
    const showRecordingWarning = async () => {
      setShowUserInteractionPanel(true);
      setButtonVisible(true);
      setCardTitle(_(msg`Session is being recorded`));
      setCardMessage(_(msg`This session is being recorded. You can join the session, but please be aware that your actions will be recorded.`));
    };

    const showSessionInvalidWarning = async () => {
      setShowUserInteractionPanel(true);
      setButtonVisible(false);
      setCardTitle(_(msg`The session you want to join does not exist.`));
      setCardMessage(_(msg`The session code you entered is invalid. Either the session does not exist or has already finished.`));
      return;
    };

    const checkIfSessionContextExists = async () => {
      // check, if the session exists
      const sessionContextResult = await fetch(`/api/sessioncontext/byShortUrl/${id}`,
        {
          method: "GET",
        });
      if (sessionContextResult.status !== 200) {
        await showSessionInvalidWarning();
      };

      let sessionContext: SessionContext = await sessionContextResult.json();
      setSessionContext(sessionContext);

      if (sessionContext.recordingMode === SessionContextRecordingMode.Always
        || sessionContext.recordingMode === SessionContextRecordingMode.WebHook) {
        await showRecordingWarning();
      } else {
        // SessionContextRecordingMode.Never
        // In this case check, if an interaction happened, because otherwise the video wont auto play
        if (hasBeenActive) {
          setShowUserInteractionPanel(false);
        }
      }
    };

    if (isEmbedded) {
      showRecordingWarning();
    } else {
      checkIfSessionContextExists();
    }
  }, [id, _, fetch, isEmbedded, hasBeenActive ]);



  const onConnectionError = () => {
    setCardMessage(_(msg`The session ended due to an error.`));
    setCardTitle(_(msg`Session ended`));
    setButtonCaption(_(msg`Click here to rejoin the session`));
    setShowUserInteractionPanel(true);
  };

  if (!showUserInteractionPanel) {
    content = <LiveSessionPage onConnectionError={onConnectionError} />
  } else {
    content = (
      <Grid container spacing={2} sx={{ flexGrow: 1 }}>
        <Grid xs={12}>
          <Card>
            <Typography level="title-lg">{cardTitle}</Typography>
            <Typography level="body-sm">{cardMessage}</Typography>
            <CardContent orientation="horizontal">
              {buttonVisible && <Button variant="solid"
                size="md"
                color="primary" onClick={() => { setShowUserInteractionPanel(false); }}>
                {buttonCaption}
              </Button>}
            </CardContent>
          </Card>
        </Grid>
      </Grid >
    );
  }

  return (
    <>
      {isEmbedded || <Breadcrumb crumbs={[{ title: _(msg`Session contexts`), link: "/" }, { title: `Session context "${sessionContext?.friendlyName}"` }]} />}
      <Box
        sx={{
          display: 'flex',
          my: 1,
          gap: 1,
          flexDirection: { xs: 'column', sm: 'row' },
          alignItems: { xs: 'start', sm: 'center' },
          flexWrap: 'wrap',
          justifyContent: 'space-between',
        }}
      >
        <Typography level="h2"><Trans>Session context "{sessionContext?.friendlyName}"</Trans>
          &nbsp;(ID: {id}<QRCodeModal value={`qvra://s?s=${id}`}><QrCode2Icon sx={{ cursor: "pointer",  verticalAlign: "middle", marginLeft: "5px" }} /></QRCodeModal>)
        </Typography>
      </Box>
      <div>
        <p><Trans>Communicate this ID to the person requiring assistance.</Trans></p>
        <div style={{position: "relative"}}> {content}</div>
      </div>
    </>
  );
};

export { SessionInteractionWrapper };
