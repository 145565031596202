import React from 'react';
import Chip from '@mui/joy/Chip';

import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import GradeIcon from '@mui/icons-material/Grade';
import { SessionContextState } from '../models/SessionContext';

export const SessionContextStateChip = ({ state }: { state: SessionContextState }) => {
  let color, decorator;
  switch (state) {
    case SessionContextState.Active:
      color = 'success';
      decorator = <GradeIcon />;
      break;
    case SessionContextState.Closed:
      color = 'neutral';
      decorator = <CheckRoundedIcon />;
      break;
    default:
      color = 'neutral';
      decorator = <GradeIcon />;
      break;
  }

  return (
    <Chip
      variant="soft"
      size="sm"
      startDecorator={decorator}
      color={color as any}
    >
      {state}
    </Chip>
  );
}
