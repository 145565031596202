import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import DialogTitle from '@mui/joy/DialogTitle';
import DialogContent from '@mui/joy/DialogContent';
import { Trans } from "@lingui/macro";
import Button from '@mui/joy/Button';
import DialogActions from '@mui/joy/DialogActions';
import { PlayCircle } from '@mui/icons-material';
import { MediaViewer } from './MediaViewer';
import {File } from '../models/File';

const MediaViewerPopup = ({ file, open, onClose }: { file: File, open: boolean, onClose: Function}) => {
  return (
    <Modal
      open={open}
      onClose={() => onClose()}
      sx={{zIndex: 9999} }
    >
      <ModalDialog>
        <DialogContent><MediaViewer file={file} /></DialogContent>
        <DialogActions>
          <Button color="neutral" onClick={() => onClose()}><Trans>Close</Trans></Button>
        </DialogActions>
      </ModalDialog>
    </Modal>
  );
}

export { MediaViewerPopup }
