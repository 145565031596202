import { useEffect, useState } from "react";
import Autocomplete from "@mui/joy/Autocomplete";
import FormControl from "@mui/joy/FormControl";
import {Trans } from "@lingui/macro";
import FormLabel from "@mui/joy/FormLabel";
import Grid from "@mui/joy/Grid";
import { useAuthenticatedFetch } from "../stores/fetch";
import { Device } from "../models/Device";
import { Profile } from "../models/Profile";


interface AutocompleteOption<T> {
  label: string;
  id: string;
  value: T
}

export function SessionContextFilter({ onDeviceChanged, onUserChanged, selectedDevice, selectedUser, showDeviceFilter = true }: { onDeviceChanged?: Function, onUserChanged?: Function, selectedDevice?: Device | null, selectedUser?: Profile | null, showDeviceFilter?: boolean }) {
  const fetch = useAuthenticatedFetch();
  
  const [deviceOptions, setDeviceOptions] = useState<AutocompleteOption<Device>[]>([]);
  const [userOptions, setUserOptions] = useState<AutocompleteOption<Profile>[]>([]);

  useEffect(() => {
    const getUserFilterValues = async () => {

      const getUsers = async () => {
        let owners : Profile[] = [];
        const url = "/api/Identity/users?take=1000";

        const result = await fetch(url, { method: "GET" });
        if (result) {
          owners = (await result.json()).items;
        }

        return owners;
      }

      const fetchedOwners = await getUsers();
      const ownerOptions = fetchedOwners.map((item) => {
        return {
          label: item.email, id: item.id, value: item
        };
      });
        
      setUserOptions(ownerOptions);
    }

    const getDeviceFilterValues = async () => {

      const getDevices = async () => {
        let devices: Device[] = [];
        const url = "/api/Device?take=1000";

        const result = await fetch(url, { method: "GET" });
        if (result) {
          devices = (await result.json()).items;
        }

        return devices;
      }

      const fetchedDevices = await getDevices();
      const deviceOptions = fetchedDevices.map((item) => {
        return { label: item.name, id: item.id, value: item  };
      });

      setDeviceOptions(deviceOptions);
    }

    getUserFilterValues();
    getDeviceFilterValues();
  }, [fetch])

  return (
    <Grid
      container
      spacing={2}
      justifyContent="flex-end"
    >
      {showDeviceFilter &&
        <Grid
          xs={3}
        >
          <FormControl size="sm">
            <FormLabel><Trans>Filter by device</Trans></FormLabel>
            <Autocomplete
              options={deviceOptions}
              onChange={(_, option) => {
                onDeviceChanged && onDeviceChanged(option ? option.value : undefined);
              }}
              value={deviceOptions.find((option) => option.id === selectedDevice?.id) || null}
            />
          </FormControl>
        </Grid>
      }
      <Grid
        xs={3}
      >
        <FormControl size="sm">
          <FormLabel><Trans>Filter by user</Trans></FormLabel>
          <Autocomplete
            options={userOptions}
            onChange={(_, option) => {
              onUserChanged && onUserChanged(option ? option.value : undefined);
            }}
            value={userOptions.find((option) => option.id === selectedUser?.id) || null}
          />
        </FormControl>
      </Grid>
    </Grid>
  );
}
