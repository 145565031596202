export enum WebHookType {
  SessionCreated = "SessionCreated",
  SessionStarted = "SessionStarted",
  SessionContextCreated = "SessionContextCreated",
  SessionContextClosed = "SessionContextClosed",
  BeforeParticipantJoinsSession = "BeforeParticipantJoinsSession",
  ParticipantJoinedSession = "ParticipantJoinedSession",
  ParticipantLeftSession = "ParticipantLeftSession",
  SessionEnded = "SessionEnded"
}

export enum WebHookMethod {
  GET = "GET",
  POST = "POST",
  PUT = "PUT",
  DELETE = "DELETE",
  PATCH = "PATCH"
}

export enum WebHookAuthenticationType {
  None = "None",
  Basic = "Basic",
  Bearer = "Bearer"
}

export interface WebHook {
  id: string;
  url: string;
  template: string;
  name: string;
  type: WebHookType;
  organisationId: string;
  createdAt: string;
  authenticationType: WebHookAuthenticationType;
  method: WebHookMethod;
  username: string;
  password: string;
  headers: any;
}
