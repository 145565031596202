import React, { useState, useEffect } from "react";
import Box from '@mui/joy/Box';
import { Breadcrumb } from "../../components/Breadcrumb";
import { msg, Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Organisation } from "../../models/Organisation";
import { OrganisationMemberTab } from "./OrganisationMemberTab";
import { useAuthenticatedFetch } from "../../stores/fetch";
import Tab from '@mui/joy/Tab';
import TabList from '@mui/joy/TabList';
import { tabClasses } from '@mui/joy/Tab';
import Tabs from '@mui/joy/Tabs';
import { Link as RouterLink } from 'react-router-dom';
import TabPanel from '@mui/joy/TabPanel';
import { WebHookTab } from "./WebHookTab";
import { useParams } from "react-router-dom";

const OrganisationPage = () => {
  const [organisation, setOrganisation] = useState<Organisation | null>(null);
  const { _ } = useLingui();
  const fetch = useAuthenticatedFetch();

  const { tab } = useParams();
  const selectedTab = tab === "webhooks" ? 1 : 0;

  const organisationName = organisation ? organisation.name : "Loading...";
  const crumbs = [{ title: _(msg`Organisation ${organisationName}`), link: "/organisation" }];
  if (tab === "webhooks") crumbs.push({ title: _(msg`WebHooks`), link: "/organisation/webhooks" });

  useEffect(() => {
    const getOrganisation = async () => {
      let url = `/api/Organisation`;
      const result = await fetch(url, { method: "GET" });
      if (result) {
        const org = await result.json();
        setOrganisation(org);
      }
    };
    getOrganisation();
  }, [fetch]);

  return (
    <Box sx={{ flex: 1, width: '100%' }}>
      <Breadcrumb crumbs={crumbs} />
      <Box
        sx={{
          position: 'sticky',
          top: { sm: -100, md: -110 },
          bgcolor: 'background.body',
          zIndex: 9995,
        }}
      >
        <Tabs
          value={selectedTab}
          sx={{
            bgcolor: 'transparent',
            flex: 1,
          }}
        >
          <TabList
            tabFlex={1}
            size="sm"
            sx={{
              pl: { xs: 0, md: 4 },
              justifyContent: 'left',
              [`&& .${tabClasses.root}`]: {
                fontWeight: '600',
                flex: 'initial',
                color: 'text.tertiary',
                [`&.${tabClasses.selected}`]: {
                  bgcolor: 'transparent',
                  color: 'text.primary',
                  '&::after': {
                    height: '2px',
                    bgcolor: 'primary.500',

                  },
                },
              },
            }}
          >
            <Tab sx={{ borderRadius: '6px 6px 0 0' }} component={RouterLink} to={"/organisation"} indicatorInset value={0}>
              <Trans>Members</Trans>
            </Tab>
            <Tab sx={{ borderRadius: '6px 6px 0 0' }} component={RouterLink} to={"/organisation/webhooks"} indicatorInset value={1}>
              <Trans>WebHooks</Trans>
            </Tab>
          </TabList>

          <TabPanel value={0}>
            <OrganisationMemberTab />
          </TabPanel>
          <TabPanel value={1}>
            <WebHookTab />
          </TabPanel>
        </Tabs>
      </Box>
    </Box>

  );
}

export { OrganisationPage };
