import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAuthenticatedFetch } from '../../stores/fetch';
import { Trans } from "@lingui/macro";
import { Device } from "../../models/Device";
import { Session } from "../../models/Session";
import { useSearchParams } from "react-router-dom";
import { SortDirection, SessionSortField, SessionPrefilter } from "../../components/ListTypes";
import { SessionTable } from "../../components/SessionTable";
import { SessionFilter } from "../../components/SessionFilter";
import { Profile } from "../../models/Profile";
import { SessionContext, } from "../../models/SessionContext";
import { TablePageLayout } from "../../components/TablePageLayout";
import { LoadingIndicator } from "../../components/LoadingIndicator";
import { SessionContextSidebar } from "./SessionContextSidebar";

const SessionListTab = () => {
  const [sessionContext, setSessionContext] = useState<SessionContext | null>(null);
  const fetch = useAuthenticatedFetch();
  let { id } = useParams();
  const prefilter = SessionPrefilter.All;
  const [searchParams] = useSearchParams();
  const [skip, setSkip] = useState<number>(0);
  const [take, setTake] = useState<number>(20);
  const [sessions, setSessions] = useState<Session[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [sortDirection, setSortDirection] = useState<SortDirection>(SortDirection.Descending);
  const [sort, setSort] = useState<SessionSortField>(SessionSortField.CreatedAt);
  const [userFilter, setUserFilter] = useState<Profile | null>(null);
  const [deviceFilter, setDeviceFilter] = useState<Device | null>(null);

  useEffect(() => {
    const getSessionContext = async () => {
      let url = `/api/SessionContext/${id}`;
      const result = await fetch(url, { method: "GET" });
      if (result) {
        const sessionContext = await result.json();
        setSessionContext(sessionContext);
      }
    };
    getSessionContext();
  }, [id, fetch]);

  if (searchParams.has("skip") && skip !== parseInt(searchParams.get("skip")!)) {
    setSkip(parseInt(searchParams.get("skip")!));
  }
  if (searchParams.has("take") && take !== parseInt(searchParams.get("take")!)) {
    setTake(parseInt(searchParams.get("take")!));
  }
  if (searchParams.has("sortDirection")) {
    let typedSortDirection = searchParams.get("sortDirection") as keyof typeof SortDirection;
    if (sortDirection !== SortDirection[typedSortDirection]) {
      setSortDirection(SortDirection[typedSortDirection]);
    }
  }

  if (searchParams.has("sort")) {
    let typedSortField = searchParams.get("sort") as keyof typeof SessionSortField;
    if (sort !== SessionSortField[typedSortField]) {
      setSort(SessionSortField[typedSortField]);
    }
  }

  useEffect(() => {
    const getSessions = async () => {
      await setLoading(true)
      let url = `/api/SessionContext/${id}/Sessions?skip=${skip}&take=${take}&sortDirection=${sortDirection}&sort=${sort}&prefilter=${prefilter}`;
      if (userFilter) url += `&user=${userFilter.id}`;
      if (deviceFilter) url += `&device=${deviceFilter.id}`;
      const result = await fetch(url, { method: "GET" });
      if (result) {
        const sessions = await result.json();
        await setSessions(sessions.items);
        await setTotalCount(sessions.totalCount);
        await setSkip(sessions.skip);
        await setTake(sessions.take);
        await setLoading(false)
      }
    };
    getSessions();
  }, [skip, take, sortDirection, sort, prefilter, fetch, id, userFilter, deviceFilter]);

  const title = sessionContext ? <Trans>Sessions in Context "{sessionContext.friendlyName}" ({sessionContext.shortURL})</Trans> : "";
  const sidebar = sessionContext ? <SessionContextSidebar sessionContext={sessionContext} /> : <LoadingIndicator />;

  return (
    <TablePageLayout loading={loading} sidebar={sidebar} title={title} >
      <SessionFilter selectedUser={userFilter} selectedDevice={deviceFilter} onDeviceChanged={(device: Device | null) => setDeviceFilter(device)} onUserChanged={(user: Profile | null) => setUserFilter(user)} />
      <SessionTable sessions={sessions} skip={skip} take={take} totalCount={totalCount} sortDirection={sortDirection} sort={sort} prefilter={prefilter} />
    </TablePageLayout>
  );
}

export { SessionListTab };
