import Select from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import { useContext } from "react";
import { locales } from "../localeLoader";
import { LocaleContext } from "../App";

const LanguageSwitcher = ({ sx }: { sx?: any }) => {

  const [ changeLocale, currentLocale ] = useContext(LocaleContext);
  return (
    <Select
      value={currentLocale}
      onChange={(e, newValue) => changeLocale!(newValue)}
    >
      {Object.keys(locales).map((key) => (
        <Option value={key} key={key}>
          {locales[key]}
        </Option>
      ))}
    </Select>
  );
}

export { LanguageSwitcher };
