import React, { useState, useEffect } from "react";
import Box from '@mui/joy/Box';
import { Breadcrumb } from "../../components/Breadcrumb";
import { msg, Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { useAuthenticatedFetch } from "../../stores/fetch";
import Tab from '@mui/joy/Tab';
import TabList from '@mui/joy/TabList';
import { tabClasses } from '@mui/joy/Tab';
import Tabs from '@mui/joy/Tabs';
import { Link as RouterLink } from 'react-router-dom';
import TabPanel from '@mui/joy/TabPanel';
import { SessionListTab } from "./SessionListTab";
import { useParams } from "react-router-dom";
import { SessionContext } from "../../models/SessionContext";
import { ChatTab } from "./ChatTab";
import { FileTab } from "./FileTab";

const SessionContextPage = () => {
  const [sessionContext, setSessionContext] = useState<SessionContext | null>(null);
  const { _ } = useLingui();
  let { id } = useParams();
  const fetch = useAuthenticatedFetch();

  const { tab } = useParams();

  const selectedTab = tab === "chat" ? 1 : tab === "files" ? 2 : 0;

  const contextName = sessionContext ? sessionContext.friendlyName : "Loading...";
  const crumbs = [{ title: _(msg`Session contexts`), link: "/sessioncontextlist/all" }, { title: _(msg`Session context "${contextName}"`), link: `/sessioncontext/${sessionContext?.id}` }];
  if (tab === "chat") crumbs.push({ title: _(msg`Chat`), link: `/sessioncontext/${sessionContext?.id}/chat` });
  if (tab === "files") crumbs.push({ title: _(msg`Files`), link: `/sessioncontext/${sessionContext?.id}/files` });

  useEffect(() => {
    const getSessionContext = async () => {
      let url = `/api/sessioncontext/${id}`;
      const result = await fetch(url, { method: "GET" });
      if (result) {
        const context = await result.json();
        setSessionContext(context);
      }
    };
    getSessionContext();
  }, [fetch, id]);

  return (
    <Box sx={{ flex: 1, width: '100%' }}>
      <Breadcrumb crumbs={crumbs} />
      <Box
        sx={{
          position: 'sticky',
          top: { sm: -100, md: -110 },
          bgcolor: 'background.body',
          zIndex: 9995,
        }}
      >
        <Tabs
          value={selectedTab}
          sx={{
            bgcolor: 'transparent',
            flex: 1,
          }}
        >
          <TabList
            tabFlex={1}
            size="sm"
            sx={{
              pl: { xs: 0, md: 4 },
              justifyContent: 'left',
              [`&& .${tabClasses.root}`]: {
                fontWeight: '600',
                flex: 'initial',
                color: 'text.tertiary',
                [`&.${tabClasses.selected}`]: {
                  bgcolor: 'transparent',
                  color: 'text.primary',
                  '&::after': {
                    height: '2px',
                    bgcolor: 'primary.500',

                  },
                },
              },
            }}
          >
            <Tab sx={{ borderRadius: '6px 6px 0 0' }} component={RouterLink} to={`/sessioncontext/${sessionContext?.id}`} indicatorInset value={0}>
              <Trans>Sessions</Trans>
            </Tab>
            <Tab sx={{ borderRadius: '6px 6px 0 0' }} component={RouterLink} to={`/sessioncontext/${sessionContext?.id}/chat`} indicatorInset value={1}>
              <Trans>Chat</Trans>
            </Tab>
            <Tab sx={{ borderRadius: '6px 6px 0 0' }} component={RouterLink} to={`/sessioncontext/${sessionContext?.id}/files`} indicatorInset value={2}>
              <Trans>Files</Trans>
            </Tab>
          </TabList>

          <TabPanel value={0}>
            <SessionListTab />
          </TabPanel>
          <TabPanel value={1}>
            <ChatTab />
          </TabPanel>
          <TabPanel value={2}>
            <FileTab />
          </TabPanel>
        </Tabs>
      </Box>
    </Box>

  );
}

export { SessionContextPage };
