import { useState } from 'react';
import Typography from '@mui/joy/Typography';
import LinearProgress from '@mui/joy/LinearProgress';
import Input from '@mui/joy/Input';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Key from '@mui/icons-material/Key';
import Stack from '@mui/joy/Stack';
import { Trans } from "@lingui/macro";

const DoublePasswordField = ({ onChange }: { onChange?: Function}) => {

  const [password, setPassword] = useState<string>('');
  const [password2, setPassword2] = useState<string>('');
  return (
    <>
      <FormControl required>
        <FormLabel><Trans>New Password</Trans></FormLabel>
        <Stack
          spacing={0.5}
          sx={{
            '--hue': Math.min(password.length * 10, 120),
          }}
        >
          <Input
            type="password"
            name="password"
            startDecorator={<Key />}
            value={password}
            onChange={async (event) => {
              await setPassword(event.target.value);
              if (onChange) onChange(event.target.value);
            }}
          />
          <LinearProgress
            determinate
            size="sm"
            value={Math.min((password.length * 100) / 8, 100)}
            sx={{
              bgcolor: 'background.level3',
              color: 'hsl(var(--hue) 80% 40%)',
            }}
          />
          <Typography
            level="body-xs"
            sx={{ alignSelf: 'flex-end', color: 'hsl(var(--hue) 80% 30%)' }}
          >
            {password.length < 3 && <Trans>Very weak</Trans>}
            {password.length >= 3 && password.length < 6 && <Trans>Weak</Trans>}
            {password.length >= 6 && password.length < 10 && <Trans>Strong</Trans>}
            {password.length >= 10 && <Trans>Very strong</Trans>}
          </Typography>
        </Stack>
      </FormControl>
      <FormControl required>
        <FormLabel><Trans>Repeat Password</Trans></FormLabel>
        <Stack
          spacing={0.5}
        >
          <Input
            startDecorator={<Key />}
            type="password"
            required
            error={password !== password2}
            onChange={async (event) => {
              await setPassword2(event.target.value);
              if (onChange) onChange(event.target.value);
            }}
            name="password2"
          />
          <Typography
            level="body-xs"
            sx={{ alignSelf: 'flex-end', color: 'red' }}
          >
            {password !== password2 && <Trans>Passwords do not match</Trans>}
          </Typography>
        </Stack>
      </FormControl>
    </>);
};

export { DoublePasswordField };
