export interface Log {
  id: string;
  timestamp: string;
  severity: LogSeverity;
  message: string;
}

export enum LogSeverity {
  Fatal = "Fatal",
  Error = "Error",
  Warning = "Warning",
  Information = "Information",
  Debug = "Debug",
  Verbose = "Verbose",
};
