import * as React from 'react';
import Link from '@mui/joy/Link';
import Table from '@mui/joy/Table';
import Sheet from '@mui/joy/Sheet';
import Checkbox from '@mui/joy/Checkbox';
import Typography from '@mui/joy/Typography';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Trans } from "@lingui/macro";
import { Pagination } from './Pagination';
import { useSearchParams } from "react-router-dom";
import { SortDirection, UserSortField, UserPrefilter } from './ListTypes';
import { Profile } from '../models/Profile';
import { ConfirmDeleteButton } from './ConfirmDeleteButton';
import { EditUserButton } from './EditUserButton';
import { Chip } from '@mui/joy';

export function UserTable({ users = [], skip = 0, take = 20, totalCount, sort, sortDirection = SortDirection.Ascending, prefilter, onFinishedEditing, onDeleteUser, isAdmin = false, profile }: { users: Profile[], skip: number, take: number, totalCount: number, sort: UserSortField, sortDirection: SortDirection, prefilter: UserPrefilter, onFinishedEditing?: Function, isAdmin?: boolean, onDeleteUser?: Function, profile: Profile }) {
  const [selected, setSelected] = React.useState<string[]>([]);
  const [searchParams, setSearchParams] = useSearchParams();

  const setSort = (sort: UserSortField) => {
    searchParams.set("sort", sort);
    setSearchParams(searchParams);
  }

  const setSortDirection = (sortDirection: SortDirection) => {
    searchParams.set("sortDirection", sortDirection);
    setSearchParams(searchParams);
  }

  const SortLink = ({ activeSort, activeSortDirection, columnName, children }: React.PropsWithChildren<{ activeSort: UserSortField, activeSortDirection: SortDirection, columnName: UserSortField }>) => {
    return (<Link
      underline="none"
      color={activeSort === columnName ? "primary" : "neutral"}
      component="button"
      onClick={() => {
        setSort(columnName);
        setSortDirection(activeSortDirection === SortDirection.Ascending ? SortDirection.Descending : SortDirection.Ascending);
      }
      }
      fontWeight="lg"
      endDecorator={activeSort === columnName ?<ArrowDropDownIcon /> : <></>}
      sx={{
        '& svg': {
          transition: '0.2s',
          transform:
            sortDirection === 'Descending' ? 'rotate(0deg)' : 'rotate(180deg)',
        },
      }}
    >
      {children}
    </Link>);
}
  return (
    <React.Fragment>
      <Sheet
        className="OrderTableContainer"
        variant="outlined"
        sx={{
          width: '100%',
          borderRadius: 'sm',
          flexShrink: 1,
          overflow: 'auto',
          minHeight: 0,
        }}
      >
        <Table
          aria-labelledby="tableTitle"
          stickyHeader
          hoverRow
          sx={{
            '--TableCell-headBackground': 'var(--joy-palette-background-level1)',
            '--Table-headerUnderlineThickness': '1px',
            '--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
            '--TableCell-paddingY': '4px',
            '--TableCell-paddingX': '8px',
          }}
        >
          <thead>
            <tr>
              <th style={{ width: 48, textAlign: 'center', padding: '12px 6px' }}>
                <Checkbox
                  size="sm"
                  indeterminate={
                    selected.length > 0 && selected.length !== users.length
                  }
                  checked={selected.length === users.length}
                  onChange={(event) => {
                    setSelected(
                      event.target.checked ? users.map((row) => row.id) : [],
                    );
                  }}
                  color={
                    selected.length > 0 || selected.length === users.length
                      ? 'primary'
                      : undefined
                  }
                  sx={{ verticalAlign: 'text-bottom' }}
                />
              </th>
              <th style={{ width: 120, padding: '12px 6px' }}>
                <SortLink activeSort={sort} activeSortDirection={sortDirection} columnName={UserSortField.FirstName}>
                  <Trans>First name</Trans>
                </SortLink>
              </th>
              <th style={{ width: 140, padding: '12px 6px' }}>
                <SortLink activeSort={sort} activeSortDirection={sortDirection} columnName={UserSortField.LastName}>
                  <Trans>Last Name</Trans>
                </SortLink>
              </th>
              <th style={{ width: 140, padding: '12px 6px' }}>
                <SortLink activeSort={sort} activeSortDirection={sortDirection} columnName={UserSortField.Email}>
                  <Trans>Email</Trans>
                </SortLink>
              </th>
              <th style={{ width: 140, padding: '12px 6px' }}>
                <Trans>Role</Trans>
              </th>
              {isAdmin &&
                <th style={{ width: 140, padding: '12px 6px' }}> </th>
              }
            </tr>
          </thead>
          <tbody>
            {users.map((row) => (
              <tr key={row.id}>
                <td style={{ textAlign: 'center', width: 120 }}>
                  <Checkbox
                    size="sm"
                    checked={selected.includes(row.id)}
                    color={selected.includes(row.id) ? 'primary' : undefined}
                    onChange={(event) => {
                      setSelected((ids) =>
                        event.target.checked
                          ? ids.concat(row.id)
                          : ids.filter((itemId) => itemId !== row.id),
                      );
                    }}
                    slotProps={{ checkbox: { sx: { textAlign: 'left' } } }}
                    sx={{ verticalAlign: 'text-bottom' }}
                  />
                </td>
                <td>
                  <Typography level="body-xs">{row.firstName}</Typography>
                </td>
                <td>
                  <Typography level="body-xs">{row.lastName}</Typography>
                </td>
                <td>
                  <Typography level="body-xs">{row.email}</Typography>
                </td>
                <td>
                  <Typography level="body-xs"><Chip>{row.userRole}</Chip></Typography>
                </td>
                {isAdmin &&
                  <td>
                    <EditUserButton userId={row.id} onFinishedEditing={() => onFinishedEditing && onFinishedEditing()} />
                    {(profile.id !== row.id) && <ConfirmDeleteButton onDeleteClick={() => onDeleteUser && onDeleteUser(row.id)} />}
                  </td>
                }
              </tr>
            ))}
          </tbody>
        </Table>
      </Sheet>
      <Pagination take={take} skip={skip} totalCount={totalCount} prefilter={prefilter} sort={sort} sortDirection={sortDirection} />
    </React.Fragment>
  );
}
