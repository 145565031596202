import * as React from 'react';
import Avatar from '@mui/joy/Avatar';
import Box from '@mui/joy/Box';
import Stack from '@mui/joy/Stack';
import Sheet from '@mui/joy/Sheet';
import Typography from '@mui/joy/Typography';
import { ChatMessage } from '../../models/ChatMessage';
import { formatAsTimeOrDate, formatDateWithAgo } from '../../utils/dateUtils';
import { MediaViewerPopup } from '../MediaViewerPopup';
import { File } from '../../models/File';
import { DownloadForOffline, Visibility } from '@mui/icons-material';

export default function ChatBubble({ variant, message }: { variant: "received" | "sent", message: ChatMessage }) {
  const isSent = variant === 'sent';
  const [openedFile, setOpenedFile] = React.useState<File | null>(null);
  let displayName = (variant === 'sent') ? 'You' : "";

  if (message.userId) {
    displayName = message.userName;
  } else if (message.deviceId) {
    displayName = message.deviceName;
  } else {
    displayName = message.autoGeneratedUserName;
  }

  const downloadAttachment = (attachmentId: string) => {
    window.open(`/api/file/${attachmentId}/content`);
  };

  return (
    <Box sx={{ maxWidth: '60%', minWidth: 'auto' }}>
      <Stack
        direction="row"
        justifyContent="space-between"
        spacing={2}
        sx={{ mb: 0.25 }}
      >
        <Typography level="body-xs">
          {displayName}
        </Typography>
        <Typography level="body-xs">{formatAsTimeOrDate(message.createdAt)}</Typography>
      </Stack>
      {(message.attachments && message.attachments.length > 0) ? (
        <Sheet
          variant="outlined"
          sx={{
            px: 1.75,
            py: 1.25,
            borderRadius: 'lg',
            borderTopRightRadius: isSent ? 0 : 'lg',
            borderTopLeftRadius: isSent ? 'lg' : 0,
          }}
        >

          {openedFile && <MediaViewerPopup file={openedFile} open={openedFile !== null} onClose={() => setOpenedFile(null)} />}
          <Typography
            level="body-sm"
            sx={{
              color: isSent
                ? 'var(--joy-palette-common-white)'
                : 'var(--joy-palette-text-primary)',
            }}
          >
            {message.text}
          </Typography>
          <Stack direction="row" spacing={1.5} alignItems="center">
            <Stack direction="column" spacing={1.5} alignItems="center">
              <Avatar color="primary" size="sm" sx={{ cursor: 'pointer' }}>
                <Visibility onClick={() => setOpenedFile(message.attachments![0])} />
              </Avatar>
              <Avatar color="primary" size="sm" sx={{ cursor: 'pointer' }}>
                <DownloadForOffline onClick={() => downloadAttachment(message.attachments![0].id)} />
              </Avatar>
            </Stack>
            <div>
              <Typography fontSize="sm">{message.attachments[0].fileName}</Typography>
              <Typography level="body-sm">{formatDateWithAgo(message.attachments[0].createdAt)}</Typography>
            </div>
          </Stack>
         
        </Sheet>
      ) : (
        <Box
          sx={{ position: 'relative' }}
        >
          <Sheet
            color={isSent ? 'primary' : 'neutral'}
            variant={isSent ? 'solid' : 'soft'}
            sx={{
              p: 1.25,
              borderRadius: 'lg',
              borderTopRightRadius: isSent ? 0 : 'lg',
              borderTopLeftRadius: isSent ? 'lg' : 0,
              backgroundColor: isSent
                ? 'var(--joy-palette-primary-solidBg)'
                : 'background.body',
            }}
          >
            <Typography
              level="body-sm"
              sx={{
                color: isSent
                  ? 'var(--joy-palette-common-white)'
                  : 'var(--joy-palette-text-primary)',
              }}
            >
                {message.text}
            </Typography>
          </Sheet>
        </Box>
      )}
    </Box>
  );
}
