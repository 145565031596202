import { useState, useEffect } from "react";
import { useUserAccountStore } from './stores/UserAccountStore';

import {
    HubConnection,
  HubConnectionBuilder,
  LogLevel
} from '@microsoft/signalr';

function useSignalR(hubURL: string): [HubConnection | null, string | null] {
  const [connectionRef, setConnection] = useState <HubConnection | null>(null);
  const [error, setError] = useState<string | null>(null);
  const token = useUserAccountStore((state) => state.token);

  function createHubConnection() {
    try {
      if (connectionRef != null) {
        connectionRef.stop();
        setConnection(null);
      }

      const con = new HubConnectionBuilder()
        .withUrl(hubURL, {
          accessTokenFactory: () => { return token! },
        })
        .configureLogging(LogLevel.Information)
        .withAutomaticReconnect()
        .build();
      setConnection(con);
    } catch (err) {
      console.log(err);
      setError(err as string);
    }
  }

  useEffect(() => {
    createHubConnection();
    // fixing the following lint makes this hook not work
  }, [token]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (connectionRef) {
      connectionRef.start()
    }
    return () => {
      if (connectionRef) {
        connectionRef.stop();
      }
    }
  }, [connectionRef]);


  return [connectionRef, error];
};

export { useSignalR };