import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAuthenticatedFetch } from '../../stores/fetch';
import { Trans } from "@lingui/macro";
import { useSearchParams } from "react-router-dom";
import { SortDirection, FileSortField, FilePrefilter } from "../../components/ListTypes";
import { SessionContext } from "../../models/SessionContext";
import { LoadingIndicator } from "../../components/LoadingIndicator";
import { FileTable } from "../../components/FileTable";
import { File } from "../../models/File";
import { MediaViewerPopup } from "../../components/MediaViewerPopup";
import { TablePageLayout } from "../../components/TablePageLayout";
import { SessionContextSidebar } from "./SessionContextSidebar";


const FileTab = () => {
  const [sessionContext, setSessionContext] = useState<SessionContext | null>(null);
  const [files, setFiles] = useState<File[]>([]);
  const fetch = useAuthenticatedFetch();
  let { id } = useParams();
  const prefilter = FilePrefilter.All;
  const [searchParams] = useSearchParams();
  const [skip, setSkip] = useState<number>(0);
  const [take, setTake] = useState<number>(20);
  const [loading, setLoading] = useState<boolean>(true);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [sortDirection, setSortDirection] = useState<SortDirection>(SortDirection.Descending);
  const [sort, setSort] = useState<FileSortField>(FileSortField.CreatedAt);
  const [openedFile, setOpenedFile] = useState<File | null>(null);
 
  useEffect(() => {
    const getSessionContext = async () => {
      let url = `/api/SessionContext/${id}`;
      const result = await fetch(url, { method: "GET" });
      if (result) {
        const sessionContext = await result.json();
        setSessionContext(sessionContext);
      }
    };
    getSessionContext();
  }, [id, fetch]);

  if (searchParams.has("skip") && skip !== parseInt(searchParams.get("skip")!)) {
    setSkip(parseInt(searchParams.get("skip")!));
  }
  if (searchParams.has("take") && take !== parseInt(searchParams.get("take")!)) {
    setTake(parseInt(searchParams.get("take")!));
  }
  if (searchParams.has("sortDirection")) {
    let typedSortDirection = searchParams.get("sortDirection") as keyof typeof SortDirection;
    if (sortDirection !== SortDirection[typedSortDirection]) {
      setSortDirection(SortDirection[typedSortDirection]);
    }
  }

  if (searchParams.has("sort")) {
    let typedSortField = searchParams.get("sort") as keyof typeof FileSortField;
    if (sort !== FileSortField[typedSortField]) {
      setSort(FileSortField[typedSortField]);
    }
  }

  useEffect(() => {
    const getFiles = async () => {
      let url = `/api/File?skip=${skip}&take=${take}&sortDirection=${sortDirection}&sort=${sort}&prefilter=${prefilter}&sessioncontext=${id}`;
      const result = await fetch(url, { method: "GET" });
      if (result) {
        const files = await result.json();
        setFiles(files.items);
        setTotalCount(files.totalCount);
        setLoading(false);
      }
    };

    getFiles();
  }, [id, fetch, prefilter, skip, sort, sortDirection, take]);

  const onSelectFile = (file: File) => {
    setOpenedFile(file);
  };

  const onDownloadFile = (file: File) => {
    window.open(`/api/file/${file.id}/content`);
  };

  const title = sessionContext ? <Trans>Sessions in Context "{sessionContext.friendlyName}" ({sessionContext.shortURL})</Trans> : "";

  const sidebar = sessionContext ? <SessionContextSidebar sessionContext={sessionContext} />  : <LoadingIndicator />;

  return (
    <TablePageLayout loading={loading} sidebar={sidebar} title={title}>

      {openedFile && <MediaViewerPopup file={openedFile} open={openedFile !== null} onClose={() => setOpenedFile(null)} />}
      <FileTable files={files} skip={skip} take={take} totalCount={totalCount} sortDirection={sortDirection} sort={sort} prefilter={prefilter} onSelectFile={onSelectFile} onDownloadFile={onDownloadFile} />
    </TablePageLayout>
  );
}

export { FileTab };
