import Button from '@mui/joy/Button';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Input from '@mui/joy/Input';
import Box from '@mui/joy/Box';
import Link from '@mui/joy/Link';
import { Link as RouterLink } from 'react-router-dom';
import Stack from '@mui/joy/Stack';
import Typography from '@mui/joy/Typography';
import FormHelperText from '@mui/joy/FormHelperText';
import Divider from '@mui/joy/Divider';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import { FormEvent, useState } from 'react';
import { useUserAccountStore } from '../../stores/UserAccountStore';
import { useNavigate } from 'react-router-dom';
import { DoublePasswordField } from '../../components/DoublePasswordField';
import { Trans } from "@lingui/macro";

interface Error {
  description: string;
}

const RegisterForm = () => {
  const [error, setError] = useState('');
  const login = useUserAccountStore((state) => state.login)
  const navigate = useNavigate();

  const doLogin = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formElements = event.currentTarget.elements;

    if ((formElements.namedItem('password') as HTMLInputElement).value === (formElements.namedItem('password2') as HTMLInputElement).value) {
      const data = {
        Email: (formElements.namedItem('email') as HTMLInputElement).value,
        OrganisationName: (formElements.namedItem('organisation') as HTMLInputElement).value,
        Password: (formElements.namedItem('password') as HTMLInputElement).value,
      };
      const answer = await fetch('/api/Identity/Register', {
        body: JSON.stringify(data),
        method: "POST",
        headers: {
          'Content-Type': 'application/json'
        }
      });
      if (answer.ok) {
        login(await answer.text());
        navigate(`/`);
      } else {
        const errors : Error[] = await answer.json();
        const errorText = errors.map((error) => error.description).join("\n");
        setError(errorText);
      }
    }
  };

  const errorField =
    error ? <FormHelperText>
      <InfoOutlined />
      {error}
    </FormHelperText> : null;

  return <>
    <Stack gap={4} sx={{ mb: 2 }}>
      <Stack gap={1}>
        <Typography level="h3"><Trans>Register</Trans></Typography>
        <Typography level="body-sm">
          <Trans>Want to login?</Trans>{' '}
          <Link to="/login" component={RouterLink} level="title-sm">
            <Trans>Click here to use your existing account!</Trans>
          </Link>
        </Typography>
      </Stack>
    </Stack>
    <Divider
      sx={(theme) => ({
        [theme.getColorSchemeSelector('light')]: {
          color: { xs: '#FFF', md: 'text.tertiary' },
          '--Divider-lineColor': {
            xs: '#FFF',
            md: 'var(--joy-palette-divider)',
          },
        },
      })}
    >
      or
    </Divider>
    <Stack gap={4} sx={{ mt: 2 }}>
      <form
        onSubmit={doLogin}
      >
        <FormControl required>
          <FormLabel><Trans>Company / Organisation name</Trans></FormLabel>
          <Input name="organisation" />
        </FormControl>
        <FormControl required>
          <FormLabel><Trans>Email adress</Trans></FormLabel>
          <Input type="email" name="email" />
        </FormControl>
        <DoublePasswordField />
        {errorField}
        <Stack gap={4} sx={{ mt: 2 }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
          </Box>
          <Button type="submit" fullWidth>
            <Trans>Register</Trans>
          </Button>
        </Stack>
      </form>
    </Stack>
  </>;
};

export { RegisterForm };
