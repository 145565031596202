import { Box, Table, Typography, useTheme } from "@mui/joy";
import { LoadingIndicator } from "../../components/LoadingIndicator";
import { Trans } from "@lingui/macro";
import { formatDateWithSeconds } from "../../utils/dateUtils";
import { Log, LogSeverity} from "../../models/Log";

const LogRowUI = ({ log }: { log: Log }) => {
  const theme = useTheme();
  let bgColor: string;
  let color: string;

  switch (log.severity) {
    case LogSeverity.Fatal:
      bgColor = theme.vars.palette.danger.solidBg;
      color = theme.vars.palette.danger.solidColor;
      break;
    case LogSeverity.Error:
      bgColor = theme.vars.palette.danger.softBg;
      color = theme.vars.palette.danger.softColor;
      break;
    case LogSeverity.Warning:
      bgColor = theme.vars.palette.warning.softBg;
      color = theme.vars.palette.warning.softColor;
      break;
    case LogSeverity.Debug:
      bgColor = theme.vars.palette.neutral.softBg;
      color = theme.vars.palette.neutral.softColor;
      break;
    default:
      bgColor = theme.vars.palette.background.body;
      color = theme.vars.palette.text.primary;
      break;
  }

  return (
    <tr style={{
      backgroundColor: bgColor,
      color: color
    }}>
      <td><Typography level="body-xs" textColor={color}>{formatDateWithSeconds(log.timestamp)}</Typography></td>
      <td><Typography level="body-xs" textColor={color}>{log.severity}</Typography></td>
      <td><Typography level="body-xs" textColor={color}>{log.message}</Typography></td>
    </tr>
  )
}



const LogViewer = ({ logs }: { logs: Log[] | null }) => {

  if (logs === null) {
    return <LoadingIndicator />;
  }

  return (
    <Box sx = {{
      overflow: 'auto',
      minHeight: '100px',
    }}>
      <Table
        aria-labelledby="tableTitle"
        stickyHeader
        sx={{
          '--TableCell-headBackground': 'var(--joy-palette-background-level1)',
          '--Table-headerUnderlineThickness': '1px',
          '--TableCell-paddingY': '4px',
          '--TableCell-paddingX': '8px',
        }}
      >
        <colgroup>
          <col style={{ width: '15%' }} />
          <col style={{ width: '10%' }} />
          <col />
        </colgroup>

        <thead>
          <tr>
            <th><Trans>Date</Trans></th>
            <th><Trans>Severity</Trans></th>
            <th><Trans>Message</Trans></th>
          </tr>
        </thead>
        <tbody>
          {logs.map((log) => (
            <LogRowUI key={log.id} log={log} />
          ))}
        </tbody>
      </Table>
    </Box>
  );
}

export { LogViewer };
