import Button from '@mui/joy/Button';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Input from '@mui/joy/Input';
import Box from '@mui/joy/Box';
import Link from '@mui/joy/Link';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import Stack from '@mui/joy/Stack';
import Typography from '@mui/joy/Typography';
import FormHelperText from '@mui/joy/FormHelperText';
import Divider from '@mui/joy/Divider';
import ErrorOutlined from '@mui/icons-material/ErrorOutlined';
import { FormEvent, ReactElement, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { DoublePasswordField } from '../../components/DoublePasswordField';
import { Trans } from "@lingui/macro";
import { useUserAccountStore } from '../../stores/UserAccountStore';

interface Error {
  description: string;
}

const ResetPasswordForm = ({ isInvite }: { isInvite: boolean}) => {
  const [error, setError] = useState<ReactElement>(<></>);
  const login = useUserAccountStore((state) => state.login)
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const email = searchParams.get("email") ||"";
  const navigate = useNavigate();

  const doPasswordReset = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formElements = event.currentTarget.elements;
    const data = {
      email: (formElements.namedItem('email') as HTMLInputElement).value,
      token: token,
      password: (formElements.namedItem('password') as HTMLInputElement).value,
    };
    const answer = await fetch('/api/Identity/reset-password', {
      body: JSON.stringify(data),
      method: "POST",
      headers: {
        'Content-Type': 'application/json'
      }
    });
    if (answer.ok) {
      login(await answer.text());
      navigate(`/`);
    } else {
      const errors : Error[] = await answer.json();
      const errorText = errors.map((error) => error.description).join("\n");
      setError(<><ErrorOutlined />{errorText}</>);
    }
  };

  const errorField =
    error ? <FormHelperText>
      {error}
    </FormHelperText> : null;

  return <>
    <Stack gap={4} sx={{ mb: 2 }}>
      {isInvite
        ? <Stack gap={1}>
          <Typography level="h3"><Trans>Accept invitation</Trans></Typography>
        </Stack>
        : <Stack gap={1}>
          <Typography level="h3"><Trans>Reset password</Trans></Typography>
            <Typography level="body-sm">
              <Trans>Do you want to login?</Trans>{' '}
              <Link component={RouterLink} to="/login" level="title-sm">
                <Trans>Click here to get back to the login form!</Trans>
              </Link>
            </Typography>
          </Stack >
        }
    </Stack>
    <Divider
      sx={(theme) => ({
        [theme.getColorSchemeSelector('light')]: {
          color: { xs: '#FFF', md: 'text.tertiary' },
          '--Divider-lineColor': {
            xs: '#FFF',
            md: 'var(--joy-palette-divider)',
          },
        },
      })}
    >
    </Divider>
    <Stack gap={4} sx={{ mt: 2 }}>
      <form
        onSubmit={doPasswordReset}
      >
        <FormControl required>
          <FormLabel><Trans>Email adress</Trans></FormLabel>
          <Input type="email" name="email" defaultValue={email} />
        </FormControl>
        <DoublePasswordField />
        {errorField}
        <Stack gap={4} sx={{ mt: 2 }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
          </Box>
          <Button name="submit" type="submit" fullWidth>
            {isInvite
              ? <Trans>Accept invitation</Trans>
              : <Trans>Reset password</Trans>
            }
          </Button>
        </Stack>
      </form>
    </Stack>
  </>;
};

export { ResetPasswordForm };
