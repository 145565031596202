import { DateTime } from "luxon";

export const formatDate = (dateString : string) => {
  if (dateString) {
    return DateTime.fromISO(dateString).toLocaleString(DateTime.DATETIME_MED);
  }
  else return null;
}

export const formatDateWithSeconds = (dateString: string) => {
  if (dateString) {
    return DateTime.fromISO(dateString).toLocaleString(DateTime.DATETIME_MED_WITH_SECONDS);
  }
  else return null;
}


export const formatDateWithAgo = (dateString : string) => {
  if (dateString) {

    // if the date is more than 1 hour in the past, use formatDate to format the date
    if (DateTime.fromISO(dateString, { zone: "UTC" }).diffNow().as('hours') < -1) {
      return formatDate(dateString);
    }
    return DateTime.fromISO(dateString, { zone: "UTC" }).toRelative();
  }
  else return null;
}

export const formatAsTimeOrDate = (dateString: string) => {
  if (dateString) {
    // show the time if the date is today. Otherwise show the date
    if (DateTime.fromISO(dateString, { zone: "UTC" }).hasSame(DateTime.now(), 'day')) {
      return DateTime.fromISO(dateString).toLocaleString(DateTime.TIME_SIMPLE);
    } else {
        return formatDate(dateString);
    }
  }
  else return null;
}
