import { create } from 'zustand'

export interface Setting {
  name: string;
  value: string;
}

interface SettingsStoreState {
  settings: Setting[];
  getSetting: (name: string) => string | undefined;
  fetch: () => Promise<void>;
}

// curried version https://stackoverflow.com/questions/69814018/zustand-typescript-persist-how-to-type-store
const useSettingsStore = create<SettingsStoreState>()((set, get) => ({
  settings: [],
  getSetting: (name : string) => {
    return get().settings.find((s) => s.name === name)?.value;
  },
  fetch: async () => {
    const response = await fetch("/api/Settings");
    set({ settings: await response.json() as Setting[] });
  },
}));

export { useSettingsStore };