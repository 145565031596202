import { createContext } from 'react';
import { Route, Routes } from 'react-router-dom';
import { AppRoutes } from './AppRoutes';
import { useSignalR } from "./useSignalR";
import { i18n } from "@lingui/core";
import { I18nProvider } from "@lingui/react";
import { useLocalization } from "./localeLoader";
import { HubConnection } from '@microsoft/signalr';
import { LoadingIndicator } from './components/LoadingIndicator';


const SignalRContext = createContext<[HubConnection |null, string | null]>([null, null]);

const LocaleContext = createContext<[Function |null, string |null]>([null, null]);

export default function App() {
  const [signalR, signalRError] = useSignalR("/remoteSupportHub");
  const { loading, changeLocale, currentLocale } = useLocalization();

  if (loading || !signalR) {
    return <LoadingIndicator />;
  }

  return (
    <SignalRContext.Provider value={[signalR, signalRError]}>
      <LocaleContext.Provider value={[changeLocale, currentLocale]}>
        <I18nProvider i18n={i18n}>
          <Routes>
          {AppRoutes.map((route, index) => {
            const { element, ...rest } = route;
            return <Route key={index} {...rest} element={element} />;
          })}
          </Routes>
        </I18nProvider>
      </LocaleContext.Provider>
    </SignalRContext.Provider>
  );
}

export { SignalRContext, LocaleContext };
