import { ReactElement, useEffect, useState, useCallback, FormEvent } from 'react';
import Card from '@mui/joy/Card';
import Typography from '@mui/joy/Typography';
import CardActions from '@mui/joy/CardActions';
import CardOverflow from '@mui/joy/CardOverflow';
import EmailRoundedIcon from '@mui/icons-material/EmailRounded';
import Button from '@mui/joy/Button';
import { Trans } from "@lingui/macro";
import Divider from '@mui/joy/Divider';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Input from '@mui/joy/Input';
import { CountrySelector, Country } from './CountrySelector';
import Stack from '@mui/joy/Stack';
import { useLingui } from "@lingui/react";
import { msg } from "@lingui/macro";
import Box from '@mui/joy/Box';
import { useAuthenticatedFetch } from "../../stores/fetch";
import { InfoOutlined, ErrorOutlined, Key } from '@mui/icons-material';
import IconButton from '@mui/joy/IconButton';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import AspectRatio from '@mui/joy/AspectRatio';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import { Profile, UserRole } from "../../models/Profile";
import Select from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import { LoadingIndicator } from '../../components/LoadingIndicator';
import { DoublePasswordField } from '../../components/DoublePasswordField';
import { Checkbox } from '@mui/joy';

export const ProfileEditorTab = ({ userId, onFinishedEditing }: { userId?: string, onFinishedEditing?: Function}) => {
  const [profile, setProfile] = useState<Profile | null>(null);
  const [error, setError] = useState<ReactElement>(<></>);
  const [showPasswordFields, setShowPasswordFields] = useState<boolean>(false);
  const fetch = useAuthenticatedFetch();
  const { _ } = useLingui();

  const isAdmin = !!userId;

  const loadProfileFromBackend = useCallback(async () => {
    const url = userId ? `/api/Identity/profile/${userId}` : "/api/Identity/myprofile";
    const result = await fetch(url, { method: "GET" });
    const profile: Profile = await result.json();
    if (profile.country === null) profile.country = "DE";
    setProfile(profile);
  }, [fetch, userId]);

  useEffect(() => {
    loadProfileFromBackend();
  }, [loadProfileFromBackend]);

  const updateProfile = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const answer = await fetch(`/api/Identity/profile/${profile!.id}`, {
      method: "PATCH",
      body: JSON.stringify(profile),
      headers: {
        'Content-Type': 'application/json'
      }
    });
    if (answer.ok) {
      setError(<><InfoOutlined /><Trans>Your profile was updated.</Trans></>);
      if (onFinishedEditing) {
        onFinishedEditing();
      }
    } else {
      try {
        const errors = await answer.clone().json();
        if (errors.title) {
          const errorText = errors.title;
          setError(<><ErrorOutlined />{errorText}</>);
        } else if (Array.isArray(errors)) {
          setError(<><ErrorOutlined />{errors.map((error) => (<p>{error.description}</p>))}</>);
        }
      } catch (ex) {
        const errorText = await answer.clone().text();
        setError(<><ErrorOutlined />{errorText}</>);
      }
    }
  };

  const updateProfileModel = (field: keyof Profile, value: string) => {
    if (!profile) return;

    let newProfile = { ...profile };
    newProfile[field] = value as any;
    setProfile(newProfile)
  };

  const updateUserRole = (value: string) => {
     if (!profile) return;
  
      let newProfile = { ...profile };
      newProfile.userRole = UserRole[value as keyof typeof UserRole];
      setProfile(newProfile)
  }

  const getBase64 = (file: File) => {
    return new Promise((resolve, reject) => {
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        resolve(reader.result);
      };
      reader.onerror = function (error) {
        reject(error);
      };
    });
  }

  const deleteImage = async () => {
    let data = {
      picture: null,
      fileName: null,
      contentType: null
    };
    await fetch(`/api/Identity/profilepicture/${profile!.id}`, {
      body: JSON.stringify(data),
      method: "POST",
      headers: {
        'Content-Type': 'application/json'
      }
    });
    await loadProfileFromBackend();
  }

  const uploadNewImage = async () => {
    let input = document.createElement('input');
    input.type = 'file';
    input.onchange = async (_) => {
      // you can use this method to get file and perform respective operations
      if (input.files && input.files.length > 0) {
        let file = input.files[0];
        if (file.size > 1024 * 1024) {
          setError(<><ErrorOutlined /><Trans>Please use an image, smaller than 1 MB</Trans></>);
        }
        if (["image/jpeg", "image/png"].indexOf(file.type) < 0) {
          setError(<><ErrorOutlined />Please use only JPEG or PNG images.</>);
        }
        // post the image to the server
        let data = {
          picture: await getBase64(file),
          fileName: file.name,
          contentType: file.type
        };
        await fetch(`/api/Identity/profilepicture/${profile!.id}`, {
          body: JSON.stringify(data),
          method: "POST",
          headers: {
            'Content-Type': 'application/json'
          }
        });
        await loadProfileFromBackend();
      }
    };
    input.click();

  };

  return (
    <Stack
      spacing={4}
      sx={{
        display: 'flex',
        maxWidth: '800px',
        mx: 'auto',
        px: { xs: 2, md: 6 },
        py: { xs: 2, md: 3 },
      }}
    >
      {profile ?
        <Card>
          <form
            onSubmit={updateProfile}
          >
          <Box sx={{ mb: 1 }}>
            <Typography level="title-md"><Trans>Personal info</Trans></Typography>
            <Typography level="body-sm">
              {userId
                ? <Trans>You can update the user profile here.</Trans>
                : <Trans>You can update your personal information here.</Trans>
              }
            </Typography>
          </Box>
          <Divider />
          <Stack
            direction="row"
            spacing={3}
            sx={{ display: 'flex', my: 1 }}
          >
            <Stack direction="column" spacing={1}>
              <AspectRatio
                ratio="1"
                maxHeight={200}
                sx={{ flex: 1, minWidth: 120, borderRadius: '100%' }}
              >{profile.hasProfilePicture ?
                <img
                  src={`/api/Identity/profilepicture/${profile.id}?hash=${Math.random()}`}
                  srcSet={`/api/Identity/profilepicture/${profile.id}?hash=${Math.random()} 2x`}
                  loading="lazy"
                  alt=""
                /> : <img src={`/user_placeholder.jpg`}
                  srcSet={`/user_placeholder.jpg 2x`}
                  loading="lazy"
                  alt="" />
                }
              </AspectRatio>
              <IconButton
                aria-label="upload new picture"
                size="sm"
                onClick={uploadNewImage }
                variant="outlined"
                color="neutral"
                sx={{
                  bgcolor: 'background.body',
                  position: 'absolute',
                  zIndex: 2,
                  borderRadius: '50%',
                  left: 100,
                  top: 170,
                  boxShadow: 'sm',
                }}
              >
                <EditRoundedIcon />
              </IconButton>
              {profile.hasProfilePicture && <IconButton
                aria-label="delete picture"
                size="sm"
                onClick={deleteImage}
                variant="outlined"
                color="neutral"
                sx={{
                  bgcolor: 'background.body',
                  position: 'absolute',
                  zIndex: 2,
                  borderRadius: '50%',
                  left: 20,
                  top: 170,
                  boxShadow: 'sm',
                }}
              >
                <DeleteRoundedIcon />
              </IconButton>}
            </Stack>
            <Stack spacing={2} sx={{ flexGrow: 1 }}>
                <Stack spacing={1}>
                  <FormLabel><Trans>Name</Trans></FormLabel>
                  <FormControl
                    sx={{ display: { sm: 'flex-column', md: 'flex-row' }, gap: 2 }}
                  >
                    <Input
                      size="sm"
                      placeholder={_(msg`First name`)}
                      error={!profile.firstName}
                      value={profile.firstName}
                      onChange={e => updateProfileModel("firstName", e.target.value)}
                    />
                  </FormControl>
                  <FormControl
                    sx={{ display: { sm: 'flex-column', md: 'flex-row' }, gap: 2 }}
                  >
                    <Input
                      size="sm"
                      placeholder={_(msg`Last name`)}
                      error={!profile.lastName}
                      sx={{ flexGrow: 1 }}
                      value={profile.lastName}
                      onChange={e => updateProfileModel("lastName", e.target.value)}
                    />
                  </FormControl>
                  <FormControl
                    sx={{ display: { sm: 'flex-column', md: 'flex-row' }, gap: 2 }}
                  >
                    <Input
                      size="sm"
                      placeholder={_(msg`Company name`)}
                      sx={{ flexGrow: 1 }}
                      value={profile.company}
                      onChange={e => updateProfileModel("company", e.target.value)}
                    />
                  </FormControl>
                </Stack>
                <Stack spacing={1}>
                  <FormLabel>Address</FormLabel>
                  <FormControl
                    sx={{ display: { sm: 'flex-column', md: 'flex-row' }, gap: 2 }}
                  >
                    <Input
                      size="sm"
                      placeholder={_(msg`Street`)}
                      error={!profile.street}
                      value={profile.street}
                      onChange={e => updateProfileModel("street", e.target.value)}
                    />
                  </FormControl>
                  <FormControl
                    sx={{ display: { sm: 'flex-column', md: 'flex-row' }, gap: 2 }}
                  >
                    <Input
                      size="sm"
                      placeholder={_(msg`Zip`)}
                      error={!profile.zip}
                      sx={{ flexGrow: 1 }}
                      value={profile.zip}
                      onChange={e => updateProfileModel("zip", e.target.value)}
                    />
                  </FormControl>
                  <FormControl
                    sx={{ display: { sm: 'flex-column', md: 'flex-row' }, gap: 2 }}
                  >
                    <Input
                      size="sm"
                      placeholder={_(msg`City`)}
                      error={!profile.city}
                      sx={{ flexGrow: 1 }}
                      value={profile.city}
                      onChange={e => updateProfileModel("city", e.target.value)}
                    />
                  </FormControl>
                </Stack>
                <Stack direction="row" spacing={2}>
                  <FormControl sx={{ flexGrow: 1 }}>
                    <FormLabel><Trans>Email</Trans></FormLabel>
                    <Input
                      size="sm"
                      type="email"
                      required
                      disabled={true}
                      startDecorator={<EmailRoundedIcon />}
                      placeholder={_(msg`Email`)}
                      value={profile.email}
                      onChange={e => updateProfileModel("email", e.target.value)}
                      sx={{ flexGrow: 1 }}
                    />
                  </FormControl>
                </Stack>
                <Stack direction="column" spacing={2}>
                  <>
                    <Checkbox label={_(msg`Change password`)} size="sm" checked={showPasswordFields} onChange={(e) => {
                      setShowPasswordFields(e.target.checked);
                      if (!e.target.checked) {
                        updateProfileModel("password", "");
                        updateProfileModel("oldPassword", "");
                      }
                    }}
                    />
                    {showPasswordFields &&
                      <>
                        {!isAdmin && <FormControl required>
                          <FormLabel><Trans>Old Password</Trans></FormLabel>
                          <Input
                            startDecorator={<Key />}
                            type="password"
                            required
                            onChange={async (event) => {
                              await updateProfileModel("oldPassword", event.target.value);
                            }}
                            name="oldPassword"
                          />
                        </FormControl>
                        }
                        <DoublePasswordField onChange={async (v: string) => { await updateProfileModel("password", v); }} />
                      </>
                    }
                  
                  </>                
                </Stack>
                <Stack direction="row" spacing={2}>
                  <FormControl sx={{ flexGrow: 1 }}>
                    <FormLabel><Trans>User role</Trans></FormLabel>
                    <Select
                      defaultValue="User"
                      required
                      disabled={!isAdmin}
                      onChange={(e, value) => updateUserRole(value!)}
                      value={profile.userRole}
                    >
                      <Option value="User">User</Option>
                      <Option value="Admin">Admin</Option>
                    </Select>
                  </FormControl>
                </Stack>
                <div>
                  <CountrySelector sx={undefined} value={profile.country} onChange={(value: Country) => updateProfileModel("country", value.code)} />
                </div>
              </Stack>
            </Stack>
            <CardOverflow sx={{ borderTop: '1px solid', borderColor: 'divider' }}>

              <p>{error}</p>
              <CardActions sx={{ alignSelf: 'flex-end', pt: 2 }}>
                {onFinishedEditing &&
                  <Button name="cancel" size="sm" variant="outlined" onClick={() => onFinishedEditing()} color="neutral">
                    <Trans>Cancel</Trans>
                  </Button>
                }
                <Button name="submit" type="submit" size="sm" variant="solid">
                  <Trans>Save</Trans>
                </Button>
              </CardActions>
            </CardOverflow>
          </form>
        </Card>
        : <LoadingIndicator />
      }
    </Stack>
  );
};



