import React from "react";
import { CircularProgress } from "@mui/joy";


const LoadingIndicator = () => {
  return (
    <div style={{ display: "flex", height: "70vh" }}>
      <div style={{ margin: 'auto', textAlign: "center" }}>
        <CircularProgress variant="soft" /><br />
        Loading...
      </div>
    </div>
  );
};

export { LoadingIndicator };
