import { Avatar, AvatarGroup } from '@mui/joy';
import { SessionParticipant } from '../models/Session';
import Box from '@mui/joy/Box';
import Typography from '@mui/joy/Typography';
import {formatDate } from '../utils/dateUtils';
import { Profile } from '../models/Profile';


export const UserProfileChip = ({ userProfile, size = "md" }: { userProfile: Profile | SessionParticipant, size?: "sm" | "md" | "lg" }) => {
  let userId = isSessionParticipant(userProfile) ? userProfile.userId : userProfile.id;

  return (<Avatar
    src={userId ? `/api/Identity/profilepicture/${userId}` : undefined}
    srcSet={userId ? `/api/Identity/profilepicture/${userId}` : undefined}
    key={userId}
    size={size}
    alt={getDisplayName(userProfile)}
  >
    {getTwoCharDisplayName(userProfile)}
  </Avatar>);
}

const ParticipantList = ({ participants }: { participants: SessionParticipant[] }) => {

  return (
    <AvatarGroup
      size="sm"
      sx={{ '--AvatarGroup-gap': '-8px', '--Avatar-size': '24px' }}
    >
      {participants.map((participant) => (
        <UserProfileChip userProfile={participant} />
      ))
      }
    </AvatarGroup>
  );
}

const DetailParticipantChip = ({ participant }: { participant: SessionParticipant }) => {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
      <Avatar
        src={participant.userId ? `/api/Identity/profilepicture/${participant.userId}` : undefined}
        srcSet={participant.userId ? `/api/Identity/profilepicture/${participant.userId}` : undefined}

      >{getTwoCharDisplayName(participant)}</Avatar>
      <Box sx={{ ml: 2 }}>
        <Typography level="title-sm" textColor="text.primary" mb={0.5}>
          {getDisplayName(participant)}
        </Typography>
        <Typography level="body-xs" textColor="text.tertiary">
          <span style={{ whiteSpace: 'nowrap' }}>{formatDate(participant.createdAt)}</span> - <span style={{ whiteSpace: 'nowrap' }}>{formatDate(participant.leftAt)}</span>
        </Typography>
      </Box>
    </Box >
  )
};


const DetailedParticipantList = ({ participants }: { participants: SessionParticipant[] }) => {
  return (
    <>
      {
        participants.map((participant: SessionParticipant) => (
          <DetailParticipantChip key={participant.id} participant={participant} />
        ))
      }
    </>
  );
}

export const getTwoCharDisplayName = (source: SessionParticipant | Profile) => {
  if (isSessionParticipant(source)) {
    if (source.userName) {
      var splittedUsername = source.userName.split(' ');
      if (splittedUsername.length === 2) {
        return splittedUsername[0].charAt(0).toUpperCase() + splittedUsername[1].charAt(0).toUpperCase()
      }

      return source.userName.slice(0, 2).toUpperCase()
    } else {
      return source.deviceName.slice(0, 2).toUpperCase()
    }
  } else {
    // Profile
    if (source.firstName && source.lastName) {
      return source.firstName.charAt(0).toUpperCase() + source.lastName.charAt(0).toUpperCase();
    } else {
      return source.email.slice(0, 2).toUpperCase();
    }

  }
}

export const getDisplayName = (source: SessionParticipant | Profile) => {
  if (isSessionParticipant(source)) {
    if (source.userName) {
      return source.userName;
    } else {
      return source.deviceName;
    }
  } else {
    return source.firstName + ' ' + source.lastName;
  }
}

const isSessionParticipant = (source: SessionParticipant | Profile): source is SessionParticipant => {
  return (source as SessionParticipant).userName !== undefined;
}


export { DetailedParticipantList, DetailParticipantChip }


export { ParticipantList }
