export enum UserRole {
  Admin = 'Admin',
  User = 'User',
  Moderator = 'Moderator',
};

export interface Profile {
  id: string;
  online: string;
  email: string;
  firstName: string;
  lastName: string;
  company: string;
  street: string;
  zip: string;
  city: string;
  country: string;
  hasProfilePicture: string;
  userRole: UserRole;

  // only used for password change
  password: string;
  oldPassword: string;
}
