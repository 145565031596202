import React, { useState, useEffect, useCallback, useContext, useMemo } from "react";
import { useParams } from "react-router-dom";
import { SignalRContext } from "../../App";
import { useAuthenticatedFetch } from '../../stores/fetch';
import { Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { useSearchParams } from "react-router-dom";
import { SessionContext } from "../../models/SessionContext";
import { LoadingIndicator } from "../../components/LoadingIndicator";
import MessagesPane from "../../components/chat/MessagesPane";
import { ChatMessage } from "../../models/ChatMessage";
import { Profile } from "../../models/Profile";
import { HubConnectionState } from "@microsoft/signalr";
import { SessionContextSidebar } from "./SessionContextSidebar";
import { TablePageLayout } from "../../components/TablePageLayout";


const ChatTab = () => {
  const [sessionContext, setSessionContext] = useState<SessionContext | null>(null);
  const { _ } = useLingui();
  const fetch = useAuthenticatedFetch();
  let { id } = useParams();
  const [searchParams] = useSearchParams();

  const [signalR, signalRError] = useContext(SignalRContext);
  const [profile, setProfile] = useState<Profile | null>(null);
  const [skip, setSkip] = useState<number>(0);
  const [take, setTake] = useState<number>(20);
  let [messages, setMessages] = useState<ChatMessage[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);

  const loading: boolean = useMemo(() => { return profile === null || sessionContext === null ||  messages === null }, [profile, sessionContext, messages]);

  const loadProfileFromBackend = useCallback(async () => {
    const url = "/api/Identity/myprofile";
    const result = await fetch(url, { method: "GET" });
    const profile: Profile = await result.json();
    setProfile(profile);
  }, [fetch]);

  useEffect(() => {
    loadProfileFromBackend();
  }, [loadProfileFromBackend]);

  useEffect(() => {
    console.log("Trying to start initial Setup");
    const getMessages = async () => {
      let url = `/api/SessionContext/${id}/chatMessages?sortDirection=Ascending&sort=CreatedAt`;
      const result = await fetch(url, { method: "GET" });
      if (result) {
        const messagesResult = await result.json();
        //@ts-ignore
        messages = messagesResult.items;
        await setMessages(messagesResult.items);
        await setTotalCount(messagesResult.totalCount);
        await setSkip(messagesResult.skip);
        await setTake(messagesResult.take);
      }
    };
    getMessages();
    const getSessionContext = async () => {
      let url = `/api/SessionContext/${id}`;
      const result = await fetch(url, { method: "GET" });
      if (result) {
        const sessionContext = await result.json();
        setSessionContext(sessionContext);
      }
    };
    const doInitialSetup = async () => {
      if (!signalR || signalR.state !== HubConnectionState.Connected) {
        console.log("SignalR not connected, waiting 1s for connection");
        setTimeout(doInitialSetup, 1000);
        return;
      }
      await signalR.invoke("SendRegisterForSessionContextEvents", id);
      signalR!.on("ProcessChatMessage", processChatMessage);
      console.log("Initial setup done");
    };

    getSessionContext();
    doInitialSetup();

    return () => {
      signalR!.off("ProcessChatMessage", processChatMessage);
    }

  }, [signalR]);

  const processChatMessage = async (urlShortCode: string, remoteConnectionId: string, viewModel: ChatMessage) => {
    messages.push(viewModel);
    const newChatMessages = [...messages];
    setMessages(newChatMessages);
  }

  const onSendMessage = (text: string) => {
    // post the message to the backend
    fetch(`/api/SessionContext/${id}/chatMessages`, {
      method: "POST",
      body: JSON.stringify({ text }),
      headers: {
        "Content-Type": "application/json"
      }
    });
  }

  const title = sessionContext ? <Trans>"{sessionContext.friendlyName}" ({sessionContext.shortURL}) Chat</Trans> : "";
  const sidebar = sessionContext ? <SessionContextSidebar sessionContext={sessionContext} /> : <LoadingIndicator />;

  return (
    <TablePageLayout loading={loading} sidebar={sidebar} title={title} >
      <MessagesPane messages={messages} onSendMessage={onSendMessage} currentUserInformation={profile ? profile.id : "" } sx={{ height: "100%" }} />
    </TablePageLayout>
  );
}

export { ChatTab };
