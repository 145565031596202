import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Grid from '@mui/joy/Grid';
import Card from '@mui/joy/Card';
import Typography from '@mui/joy/Typography';
import Box from '@mui/joy/Box';
import { Breadcrumb } from "../../components/Breadcrumb";
import { useAuthenticatedFetch } from '../../stores/fetch';
import { msg, Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import CircularProgress from '@mui/joy/CircularProgress';
import { Device } from "../../models/Device";
import { formatDate } from "../../utils/dateUtils";
import { DeviceTokenChip } from "./DeviceTokenChip";
import { SessionContext } from "../../models/SessionContext";
import { useSearchParams } from "react-router-dom";
import { SortDirection, SessionContextSortField, SessionContextPrefilter } from "../../components/ListTypes";
import { SessionContextTable } from "../../components/SessionContextTable";
import { SessionContextFilter } from "../../components/SessionContextFilter";
import { Profile } from "../../models/Profile";
import { LoadingIndicator } from "../../components/LoadingIndicator";

const DevicePage = () => {
  const [device, setDevice] = useState<Device | null>(null);
  const { _ } = useLingui();
  const fetch = useAuthenticatedFetch();
  let { id } = useParams();
  const prefilter = SessionContextPrefilter.All;
  const [searchParams] = useSearchParams();
  const [skip, setSkip] = useState<number>(0);
  const [take, setTake] = useState<number>(20);
  const [sessionContexts, setSessionContexts] = useState<SessionContext[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [sortDirection, setSortDirection] = useState<SortDirection>(SortDirection.Ascending);
  const [sort, setSort] = useState<SessionContextSortField>(SessionContextSortField.Id);
  const [userFilter, setUserFilter] = useState<Profile | null>(null);

  useEffect(() => {
    const getDevice = async () => {
      let url = `/api/Device/${id}`;
      const result = await fetch(url, { method: "GET" });
      if (result) {
        const device = await result.json();
        setDevice(device);
      }
    };
    getDevice();
  }, [id, fetch]);

  if (searchParams.has("skip") && skip !== parseInt(searchParams.get("skip")!)) {
    setSkip(parseInt(searchParams.get("skip")!));
  }
  if (searchParams.has("take") && take !== parseInt(searchParams.get("take")!)) {
    setTake(parseInt(searchParams.get("take")!));
  }
  if (searchParams.has("sortDirection")) {
    let typedSortDirection = searchParams.get("sortDirection") as keyof typeof SortDirection;
    if (sortDirection !== SortDirection[typedSortDirection]) {
      setSortDirection(SortDirection[typedSortDirection]);
    }
  }

  if (searchParams.has("sort")) {
    let typedSortField = searchParams.get("sort") as keyof typeof SessionContextSortField;
    if (sort !== SessionContextSortField[typedSortField]) {
      setSort(SessionContextSortField[typedSortField]);
    }
  }

  useEffect(() => {
    const getSessionContexts = async () => {
      await setLoading(true)
      let url = `/api/SessionContext?skip=${skip}&take=${take}&sortDirection=${sortDirection}&sort=${sort}&prefilter=${prefilter}&device=${id}`;
      if (userFilter) url += `&user=${userFilter.id}`;
      const result = await fetch(url, { method: "GET" });
      if (result) {
        const sessionContexts = await result.json();
        await setSessionContexts(sessionContexts.items);
        await setTotalCount(sessionContexts.totalCount);
        await setSkip(sessionContexts.skip);
        await setTake(sessionContexts.take);
        await setLoading(false)
      }
    };
    getSessionContexts();
  }, [skip, take, sortDirection, sort, prefilter, fetch, id, userFilter]);

  let contents = !device
    ? <LoadingIndicator />
    : <>
      <Grid container spacing={2} sx={{ flexGrow: 1 }}>
        <Grid xs={9}>
          <Card>
            <Typography level="title-lg"><Trans>Session contexts using {device.name}</Trans></Typography>
            <Typography level="body-sm">{device.description}</Typography>
            {loading ?
              <div style={{ display: "flex", height: "70vh" }}><div style={{ margin: 'auto', textAlign: "center" }}><CircularProgress variant="soft" /><br /><Trans>Loading...</Trans></div></div> :
              <>
                <SessionContextFilter selectedUser={userFilter} showDeviceFilter={false} onUserChanged={(user: Profile | null) => setUserFilter(user)} />
                <SessionContextTable sessionContexts={sessionContexts} skip={skip} take={take} totalCount={totalCount} sortDirection={sortDirection} sort={sort} prefilter={prefilter} />
              </>
            }
          </Card>
        </Grid>
        <Grid xs={3}>
          <Card>
            <Typography level="title-lg"><Trans>Device information</Trans></Typography>
            <Typography level="body-sm"><Trans>Device ID: {device.id}</Trans></Typography>
            <Typography level="body-sm"><Trans>Created at: {formatDate(device.createdAt)}</Trans></Typography>
            <Typography level="body-sm"><Trans>Organisation: {device.organisationName}</Trans></Typography>
            <Typography level="body-sm"><Trans>Device Token: </Trans>&nbsp;
              <DeviceTokenChip device={device} onNewToken={(newDevice: Device) => setDevice(newDevice)} />
            </Typography>
          </Card>

        </Grid>
      </Grid>
    </>


  const deviceName = device ?device.name: "Loading...";
  return (
    <>
      <Breadcrumb crumbs={[{ title: _(msg`Devices`), link: "/devicelist/all" }, { title: _(msg`Device ${deviceName}`) }]} />
      <Box
        sx={{
          display: 'flex',
          my: 1,
          gap: 1,
          flexDirection: { xs: 'column', sm: 'row' },
          alignItems: { xs: 'start', sm: 'center' },
          flexWrap: 'wrap',
          justifyContent: 'space-between',
        }}
      >
        <Typography level="h2"><Trans>Device {deviceName}</Trans></Typography>
      </Box>
      <div>
        <div style={{ position: "relative" }}> {contents}</div>
      </div>
    </>

  );
}

export { DevicePage };
