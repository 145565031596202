import React, { useState, useEffect } from "react";
import Typography from '@mui/joy/Typography';
import { useAuthenticatedFetch } from '../../stores/fetch';
import { Trans } from "@lingui/macro";
import Card from '@mui/joy/Card';
import Stack from '@mui/joy/Stack';
import { LogViewer } from "./LogViewer";
import { Log } from "../../models/Log";
import { TablePageLayout } from "../../components/TablePageLayout";

const LogTab = () => {
  const [logs, setLogs] = useState<Log[] | null>(null);
  const fetch = useAuthenticatedFetch();

  useEffect(() => {
    const loadTokensFromBackend = async () => {
      const result = await fetch("/api/Log", { method: "GET" });
      const logs: Log[] = await result.json();
      setLogs(logs);
      setTimeout(async () => {
        await loadTokensFromBackend();
      }, 2000);
    };

    loadTokensFromBackend();
  }, [fetch]);

  const title = <Trans>System logs</Trans>;

  return (
    <TablePageLayout loading={logs === null} sidebar={undefined} title={title}>
      <Typography level="body-sm">
        <Trans>You can see the last 1000 log messages here.<br />If you need more information, please refer to the log files in the application's folder.</Trans>
      </Typography>
      <LogViewer logs={logs} />
    </TablePageLayout>
  );
}



export { LogTab };
