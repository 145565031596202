import { Card, CardContent, Chip, Grid, Typography } from '@mui/joy';
import {Trans } from "@lingui/macro";
import { QRCodeChipDecorator } from './QRCodeChipDecorator';

/**
 * AppInstaller component to display the download links for the Remote Assist app
 * @returns
 */
const AppInstaller = () => {
  const appxDownloadUrl = `${window.location.origin}/QVizarRemoteAssist.appxbundle`;

  return (
    <Grid container spacing={2} sx={{ display: "flex", alignItems: "stretch" }}>
      <Grid xs={6} sx={{ display: "flex" }}>
        <Card sx={{ flex: 1, display: "flex", flexDirection: "column" }}>
          <CardContent sx={{ flex: 1 }}>
            <Typography level="h4"><Trans>Download the Remote Assist app for iOS</Trans></Typography>
            <Typography level="body-md"><Trans>Download the Remote Assist app for your iPad or iPhone</Trans></Typography>
            <img
              src="/app-store-badge.png"
              alt="Download on the App Store"
              style={{ cursor: "pointer", maxHeight: "80px", margin: "auto", maxWidth: "20vw" }}
              onClick={() => window.open('https://apps.apple.com/app/qvizarremoteassist/id6639614429')}
            />
          </CardContent>
        </Card>
      </Grid>
      <Grid xs={6} sx={{ display: "flex" }}>
        <Card sx={{ flex: 1, display: "flex", flexDirection: "column" }}>
          <CardContent sx={{ flex: 1 }}>
            <Typography level="h4"><Trans>Download the Remote Assist app for HoloLens 2</Trans></Typography>
            <Typography level="body-md"><Trans>Download the Remote Assist app for your HoloLens 2 device</Trans></Typography>
              <img
                src="/hololens-badge.png"
                alt="Install on HoloLens"
                style={{ cursor: "pointer", maxHeight: "80px", margin: "auto", maxWidth: "20vw" }}
                onClick={() => window.open(`ms-appinstaller:?source=${encodeURIComponent(appxDownloadUrl)}`)}
            />
            <Typography sx={{ textAlign: "center" }}><Chip endDecorator={<QRCodeChipDecorator title={<Trans>Scan this code on Hololens</Trans>} value={`ms-appinstaller:?source=${appxDownloadUrl}`} />}><Trans>(Download the appxbundle file <a href={appxDownloadUrl} download>here</a>)</Trans></Chip></Typography>
          </CardContent>
        </Card>
    </Grid>
  </Grid>
  );
};

export { AppInstaller }
