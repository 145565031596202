import React, { useEffect, useState, ReactElement } from "react";
import { ErrorOutlined } from '@mui/icons-material';
import { Trans } from "@lingui/macro";
import Button from "@mui/joy/Button";
import Modal from "@mui/joy/Modal";
import ModalDialog from "@mui/joy/ModalDialog";
import DialogTitle from "@mui/joy/DialogTitle";
import DialogContent from "@mui/joy/DialogContent";
import Typography from "@mui/joy/Typography";
import Stack from "@mui/joy/Stack";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import Input from "@mui/joy/Input";
import Select from "@mui/joy/Select";
import Option from "@mui/joy/Option";
import DialogActions from "@mui/joy/DialogActions";

import { useAuthenticatedFetch } from "../../stores/fetch";
import { Profile } from "../../models/Profile";


// A component that renders a button to add an API token to the user's profile.
//It opens a modal dialog to enter the token name and validity in days.The token is then added to the user's profile.

const AddApiTokenButton = ({ onTokenAdded }: { onTokenAdded: Function}) => {
  const [profile, setProfile] = useState<Profile | null>(null);
  const [open, setOpen] = useState<boolean>(false);
  const [name, setName] = useState<string>("New token");
  const [expiresInDays, setExpiresInDates] = useState<number>(365);
  const fetch = useAuthenticatedFetch();
  const [error, setError] = useState<ReactElement>(<></>);

  useEffect(() => {
    //reset the form when the dialog is closed
    if (!open) {
      setName("New token");
      setExpiresInDates(365);
      setError(<></>);
    }
  }, [open]);

  useEffect(() => {
    const loadProfileFromBackend = async () => {
      const result = await fetch("/api/Identity/myprofile", { method: "GET" });
      const profile: Profile = await result.json();
      setProfile(profile);
    };
    loadProfileFromBackend();
  }, [fetch]);

  const addToken = async () => {
    const answer = await fetch("/api/Identity/addtoken", {
      method: "post",
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ tokenName: name, validityInDays: expiresInDays, userId: profile!.id })
    });
    if (answer.ok) {
      const token = await answer.json();
      onTokenAdded(token);
      setOpen(false);
    } else {
      const errors = await answer.json();
      const errorText = errors.title;
      setError(<><ErrorOutlined />{errorText}</>);
    }
  };

  return (<>
    <Button onClick={() => setOpen(true)} size="sm" variant="solid">
      <Trans>Add</Trans>
    </Button>
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      sx={{zIndex: 11000}}
    >
      <ModalDialog>
        <DialogTitle><Trans>Add API Token</Trans></DialogTitle>
        <DialogContent><Trans>Please enter the following information to add a new API token to your user account.</Trans></DialogContent>
        <Stack spacing={2}>
          <FormControl>
            <FormLabel>Token name</FormLabel>
            <Input value={ name } autoFocus required type="text" onChange={(event) => setName(event.target.value)} />
          </FormControl>
          <FormControl>
            <FormLabel>Expires in</FormLabel>
            <Select
              defaultValue={expiresInDays}
              onChange={(event, newValue) => setExpiresInDates(newValue!)}
            >
              <Option value={31}>31 Days</Option>
              <Option value={365}>1 Year</Option>
              <Option value={1095}>3 Years</Option>
            </Select>
          </FormControl>
        </Stack>
        {error && <Typography>{error}</Typography>}
        <DialogActions>
          <Button color="primary" onClick={() => addToken()}><Trans>Add token</Trans></Button>
          <Button color="neutral" onClick={() => setOpen(false)}><Trans>Cancel</Trans></Button>
        </DialogActions>
      </ModalDialog>
    </Modal>
  </>);
};

export { AddApiTokenButton }
