import { Session } from "./Session";
import { SessionParticipant } from "./Session";

export enum SessionContextState {
  Active = "Active",
  Closed = "Closed"
}

export enum SessionContextRecordingMode {
  Always = "Always",
  Never = "Never",
  WebHook = "WebHook"
}

export interface SessionContext {
  id: string;
  shortURL: string;
  ownerId: string;
  createdAt: string;
  finishedAt: string;
  state: SessionContextState;
  recordingMode: SessionContextRecordingMode,
  friendlyName: string;
  ownerName: string;
  organisationName: string;
  organisationId: string;
  participants: SessionParticipant[];
  sessions: Session[];
}
