import Box from '@mui/joy/Box';
import TabPanel from '@mui/joy/TabPanel';
import Tabs from '@mui/joy/Tabs';
import TabList from '@mui/joy/TabList';
import Tab, { tabClasses } from '@mui/joy/Tab';
import { Trans } from "@lingui/macro";
import { msg } from "@lingui/macro";
import { LogTab } from "./LogTab";
import { useLingui } from "@lingui/react";

import { Breadcrumb } from "../../components/Breadcrumb";

export function SiteAdminPage() {


  const { _ } = useLingui(); 


  return (
    <Box sx={{ flex: 1, width: '100%' }}>

      <Breadcrumb crumbs={[{ title: _(msg`Site Admin`) }]} />
      <Box
        sx={{
          position: 'sticky',
          top: { sm: -100, md: -110 },
          bgcolor: 'background.body',
          zIndex: 9995,
        }}
      >
        <Tabs
          defaultValue={0}
          sx={{
            bgcolor: 'transparent',
          }}
        >
          <TabList
            tabFlex={1}
            size="sm"
            sx={{
              pl: { xs: 0, md: 4 },
              justifyContent: 'left',
              [`&& .${tabClasses.root}`]: {
                fontWeight: '600',
                flex: 'initial',
                color: 'text.tertiary',
                [`&.${tabClasses.selected}`]: {
                  bgcolor: 'transparent',
                  color: 'text.primary',
                  '&::after': {
                    height: '2px',
                    bgcolor: 'primary.500',
                  },
                },
              },
            }}
          >
            <Tab sx={{ borderRadius: '6px 6px 0 0' }} indicatorInset value={0}>
              <Trans>Server Logs</Trans>
            </Tab>
          </TabList>

          <TabPanel value={0}>
            <LogTab />
          </TabPanel>
        </Tabs>
      </Box>
    </Box>
  );
}
