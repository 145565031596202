import { useEffect, useState } from 'react';
import Grid from '@mui/joy/Grid';
import Card from '@mui/joy/Card';
import { useAuthenticatedFetch } from '../../stores/fetch';
import { Trans } from '@lingui/macro';

import { formatDate } from "../../utils/dateUtils";
import { useSearchParams } from "react-router-dom";
import { SortDirection, UserSortField, UserPrefilter } from "../../components/ListTypes";
import { UserTable } from "../../components/UserTable";
import { InviteUserButton } from "../../components/InviteUserButton";
import { Profile } from "../../models/Profile";
import { Organisation } from "../../models/Organisation";
import Stack from '@mui/joy/Stack';
import Typography from '@mui/joy/Typography';
import { LoadingIndicator } from '../../components/LoadingIndicator';
import { TablePageLayout } from '../../components/TablePageLayout';



export const OrganisationMemberTab = () => {
  const fetch = useAuthenticatedFetch();
  const prefilter = UserPrefilter.All;
  const [searchParams] = useSearchParams();
  const [skip, setSkip] = useState<number>(0);
  const [take, setTake] = useState<number>(20);
  const [users, setUsers] = useState<Profile[]>([]);
  const [organisation, setOrganisation] = useState<Organisation | null>(null);
  const [profile, setProfile] = useState<Profile | null>(null);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [sortDirection, setSortDirection] = useState<SortDirection>(SortDirection.Ascending);
  const [sort, setSort] = useState<UserSortField>(UserSortField.Id);
  const [reloadTrigger, setReloadTrigger] = useState<Date>(new Date());
  const [isAdmin, setIsAdmin] = useState<boolean>(false);


  useEffect(() => {
    const getOrganisation = async () => {
      let url = `/api/Organisation`;
      const result = await fetch(url, { method: "GET" });
      if (result) {
        const org = await result.json();
        setOrganisation(org);
      }
    };
    const getOwnProfile = async () => {
      let url = `/api/Identity/myprofile`;
      const result = await fetch(url, { method: "GET" });
      if (result) {
        const profile = await result.json();
        setProfile(profile);
        setIsAdmin(profile.userRole === "Admin");
      }
    };
    getOwnProfile();
    getOrganisation();
  }, [fetch]);

  const onDeleteUser = async (id: string) => {
    let url = `/api/Identity/profile/${id}`;
    const result = await fetch(url, { method: "DELETE" });
    if (result) {
      setReloadTrigger(new Date());
    }
  };

  if (searchParams.has("skip") && skip !== parseInt(searchParams.get("skip")!)) {
    setSkip(parseInt(searchParams.get("skip")!));
  }
  if (searchParams.has("take") && take !== parseInt(searchParams.get("take")!)) {
    setTake(parseInt(searchParams.get("take")!));
  }
  if (searchParams.has("sortDirection")) {
    let typedSortDirection = searchParams.get("sortDirection") as keyof typeof SortDirection;
    if (sortDirection !== SortDirection[typedSortDirection]) {
      setSortDirection(SortDirection[typedSortDirection]);
    }
  }

  if (searchParams.has("sort")) {
    let typedSortField = searchParams.get("sort") as keyof typeof UserSortField;
    if (sort !== UserSortField[typedSortField]) {
      setSort(UserSortField[typedSortField]);
    }
  }

  useEffect(() => {
    const getSessions = async () => {
      await setLoading(true)
      let url = `/api/Identity/users?skip=${skip}&take=${take}&sortDirection=${sortDirection}&sort=${sort}&prefilter=${prefilter}`;
      const result = await fetch(url, { method: "GET" });
      if (result) {
        const users = await result.json();
        await setUsers(users.items);
        await setTotalCount(users.totalCount);
        await setSkip(users.skip);
        await setTake(users.take);
        await setLoading(false)
      }
    };
    getSessions();
  }, [skip, take, sortDirection, sort, prefilter, fetch, reloadTrigger]);


  const title = organisation ? <Trans>Members of {organisation.name}</Trans> : "";
  const sidebar = organisation ? <Card>
    <Typography level="title-lg"><Trans>Organisation information</Trans></Typography>
    <Typography level="body-sm"><Trans>Organisation ID: {organisation.id}</Trans></Typography>
    <Typography level="body-sm"><Trans>Created at: {formatDate(organisation!.createdAt)}</Trans></Typography>
    <Typography level="body-sm"><Trans>Organisation: {organisation.name}</Trans></Typography>
    {isAdmin &&
      <InviteUserButton organisation={organisation} onFinished={() => setReloadTrigger(new Date())} />
    }
  </Card> : <LoadingIndicator />;

  return (
    <TablePageLayout loading={loading} sidebar={sidebar} title={title} >
      <UserTable users={users} profile={profile!} skip={skip} take={take} totalCount={totalCount} sortDirection={sortDirection} sort={sort} prefilter={prefilter} isAdmin={isAdmin} onDeleteUser={(id: string) => onDeleteUser(id)} onFinishedEditing={() => setReloadTrigger(new Date())} />
    </TablePageLayout>
  );
};
