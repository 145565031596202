import { StartNewSessionPage } from "./container/LiveSessionPage/StartNewSessionPage";
import { SessionInteractionWrapper } from "./container/LiveSessionPage/SessionInteractionWrapper";
import { LoginPage } from "./container/LoginPage/LoginPage";
import { ProfilePage } from "./container/ProfilePage/ProfilePage";
import { Layout } from "./components/Layout";
import { SessionContextListPage } from "./container/SessionContextListPage/SessionContextListPage";
import { Sessionpage } from "./container/SessionPage/SessionPage";
import { DeviceListPage } from "./container/DeviceListPage/DeviceListPage";
import { NewDevicePage } from "./container/DevicePage/NewDevicePage";
import { DevicePage } from "./container/DevicePage/DevicePage";
import { OrganisationPage } from "./container/OrganisationPage/OrganisationPage";
import { SiteAdminPage } from "./container/SiteAdminPage/SiteAdminPage";
import { WebHookPage } from "./container/WebHookPage/WebHookPage";
import { SessionContextPage } from "./container/SessionContextPage/SessionContextPage";

const AppRoutes = [
  {
    index: true,
    element: <Layout><StartNewSessionPage /></Layout>
  },
  {
    path: '/sessioncontextlist/:prefilter',
    element: <Layout><SessionContextListPage /></Layout>
  },
  {
    path: '/sessioncontext/:id/:tab?',
    element: <Layout><SessionContextPage /></Layout>
  },
  {
    path: '/devicelist/new',
    element: <Layout><NewDevicePage /></Layout>
  },
  {
    path: '/devicelist/:prefilter',
    element: <Layout><DeviceListPage /></Layout>
  },
  {
    path: '/device/:id',
    element: <Layout><DevicePage /></Layout>
  },
  {
    path: '/session/:id',
    element: <Layout><Sessionpage /></Layout>
  },
  {
    path: '/livesession/:id',
    element: <Layout><SessionInteractionWrapper /></Layout>
  },
  {
    path: '/webhook/:id',
    element: <Layout><WebHookPage /></Layout>
  },
  {
    path: '/profile',
    element: <Layout><ProfilePage /></Layout>
  },
  {
    path: '/organisation/:tab?',
    element: <Layout><OrganisationPage /></Layout>
  },
  {
    path: '/siteadmin',
    element: <Layout><SiteAdminPage /></Layout>
  },
  {
    path: '/login/:action?',
    element: <LoginPage />
  },
];

export { AppRoutes };
