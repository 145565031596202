import { useUserAccountStore } from './UserAccountStore';
import { useShallow } from 'zustand/react/shallow'
import { useCallback } from 'react';
import { useToastStore } from './ToastStore';

// export the browser's default fetch as fetch
const useUnauthenticatedFetch = () => async (url: string, options: RequestInit): Promise<any> => {
  try {
    const result = await fetch(url, options);
    return result;
  } catch (error) {

    console.log(error);
    throw error;
  }
}

// export a custom version of fetch that automatically adds an Authorization header
// to any request made to the same domain the site is hosted on
const useAuthenticatedFetch = () => {
  const [token, logout] = useUserAccountStore(useShallow((state) => [state.token, state.logout]));
  const addMessage = useToastStore((store) => store.addMessage);

  const authedFetch = useCallback(async (url: string, options: RequestInit) : Promise<any> => {
    // add the Authorization header to the options object
    options = {
      ...options,
      headers: {
        ...options?.headers,
        Authorization: token!,
      },
    };

    // call the browser's default fetch with the url and options
    try {
      const result = await fetch(url, options);
      if (result && result.ok) {
        return result;
      } else if (result && result.status === 500) {
        addMessage({ title: "Internal Server Error", content: "An error occurred while fetching data", lifeTime: 5000 })
      } else if (result && (result.status === 401 || result.status === 403)) {
        // if the response is a 401, then the token is no longer valid
        // so we need to log the user out
        logout();
        addMessage({ title: "Error", content: "An error occurred while fetching data", lifeTime: 5000 });
        //await navigate("/login?path=" + encodeURIComponent("/" + window.location.pathname + window.location.search));
      } else {
        return result;
      }
    } catch (error) {
      addMessage({ title: "Error", content: "An error occurred while fetching data", lifeTime: 5000 });
      console.log(error);
    }
  }, [logout, token, addMessage]);

  return authedFetch;
};


export { useAuthenticatedFetch, useUnauthenticatedFetch };
