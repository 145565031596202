import { Card, Grid, Typography } from '@mui/joy';
import { PropsWithChildren, ReactNode } from 'react';
import { LoadingIndicator } from './LoadingIndicator';

const TablePageLayout = ({ children, sidebar, loading, title }: { loading: boolean, sidebar: ReactNode | null, title: string | ReactNode} & PropsWithChildren) => {

  if (loading) {
    return <LoadingIndicator />;
  }

  return (
    <Grid container spacing={2} sx={{ flexGrow: 1 }}>
      <Grid xs={sidebar ? 9 : 12}>
        <Card>
          <Typography level="title-lg">{title}</Typography>
          {children}
        </Card>
      </Grid>
      {sidebar && <Grid xs={3}>{sidebar}</Grid>}
    </Grid>
  );
};

export { TablePageLayout };
