import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Grid from '@mui/joy/Grid';
import Card from '@mui/joy/Card';
import Typography from '@mui/joy/Typography';
import Box from '@mui/joy/Box';
import { LoadingIndicator } from "../../components/LoadingIndicator";
import { Breadcrumb } from "../../components/Breadcrumb";
import { useAuthenticatedFetch } from '../../stores/fetch';
import { msg, Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Session } from "../../models/Session";
import { formatDate } from "../../utils/dateUtils";
import { DetailedParticipantList } from "../../components/ParticipantList";
import { FileTable } from "../../components/FileTable";
import { File } from "../../models/File";
import { useSearchParams } from "react-router-dom";
import { SortDirection, FileSortField, FilePrefilter } from "../../components/ListTypes";
import { MediaViewerPopup } from "../../components/MediaViewerPopup";

const Sessionpage = () => {
  const [session, setSession] = useState<Session | null>(null);
  const [files, setFiles] = useState<File[]>([]);
  const { _ } = useLingui();
  const fetch = useAuthenticatedFetch();
  let { id } = useParams();
  const prefilter = FilePrefilter.All;
  const [searchParams] = useSearchParams();
  const [skip, setSkip] = useState<number>(0);
  const [take, setTake] = useState<number>(20);
  const [sortDirection, setSortDirection] = useState<SortDirection>(SortDirection.Ascending);
  const [sort, setSort] = useState<FileSortField>(FileSortField.Id);
  const [totalCount, setTotalCount] = useState<number>(0);

  const [openedFile, setOpenedFile] = useState<File | null>(null);

  if (searchParams.has("skip") && skip !== parseInt(searchParams.get("skip")!)) {
    setSkip(parseInt(searchParams.get("skip")!));
  }
  if (searchParams.has("take") && take !== parseInt(searchParams.get("take")!)) {
    setTake(parseInt(searchParams.get("take")!));
  }
  if (searchParams.has("sortDirection")) {
    let typedSortDirection = searchParams.get("sortDirection") as keyof typeof SortDirection;
    if (sortDirection !== SortDirection[typedSortDirection]) {
      setSortDirection(SortDirection[typedSortDirection]);
    }
  }

  if (searchParams.has("sort")) {
    let typedSortField = searchParams.get("sort") as keyof typeof FileSortField;
    if (sort !== FileSortField[typedSortField]) {
      setSort(FileSortField[typedSortField]);
    }
  }

  useEffect(() => {
    const getSession = async () => {
      let url = `/api/Session/${id}`;
      const result = await fetch(url, { method: "GET" });
      if (result) {
        const session = await result.json();
        setSession(session);
      }
    };
    const getRecordings = async () => {
      let url = `/api/File?skip=${skip}&take=${take}&sortDirection=${sortDirection}&sort=${sort}&prefilter=${prefilter}&session=${id}`;
      const result = await fetch(url, { method: "GET" });
      if (result) {
        const files = await result.json();
        setFiles(files.items);
        setTotalCount(files.totalCount);
        
      }
    };

    getSession();
    getRecordings();
  }, [id, fetch, prefilter, skip, sort, sortDirection, take]);

  const onDownloadFile = (file: File) => {
    window.open(`/api/file/${file.id}/content`);
  };

  let contents = !session
    ? <LoadingIndicator />
    : <>

      {openedFile && <MediaViewerPopup file={openedFile} open={openedFile !== null} onClose={() => setOpenedFile(null)} />}

      <Grid container spacing={2} sx={{ flexGrow: 1 }}>
        <Grid xs={9}>
          <Card>
            <Typography level="title-lg"><Trans>Files</Trans></Typography>
            {files === null ? <LoadingIndicator /> :
              files.length === 0 ?
                <Trans>No files</Trans> :
                <>
                  <FileTable files={files} skip={skip} take={take} totalCount={totalCount} sortDirection={sortDirection} sort={sort} prefilter={prefilter} onSelectFile={(file: File) => setOpenedFile(file)} onDownloadFile={onDownloadFile} />
                </>
            }
          </Card>
        </Grid>
        <Grid xs={3}>
          <Card>
            <Typography level="title-lg"><Trans>Session</Trans></Typography>
            <Typography level="body-sm"><Trans>Session ID: {session.id}</Trans></Typography>
            {/*<Typography level="body-sm"><Trans>Short Code: {session.shortURL}</Trans></Typography>*/}
            <Typography level="body-sm"><Trans>Started at: {formatDate(session.createdAt)}</Trans></Typography>
            <Typography level="body-sm"><Trans>FinishedAt: {formatDate(session.finishedAt)}</Trans></Typography>
            <Typography level="body-sm"><Trans>Organisation: {session.organisationName}</Trans></Typography>
          </Card>

          <Card sx={{marginTop: 2}}>
            <Typography level="title-lg"><Trans>Participants</Trans></Typography>
            <DetailedParticipantList participants={session.participants} />
          </Card>
        </Grid>
      </Grid>
    </>

  return (
    <>
      <Breadcrumb crumbs={[{ title: _(msg`All session contexts`), link: "/sessioncontextlist/all" }, { title: _(msg`Session ${id}`) }]} />
      <Box
        sx={{
          display: 'flex',
          my: 1,
          gap: 1,
          flexDirection: { xs: 'column', sm: 'row' },
          alignItems: { xs: 'start', sm: 'center' },
          flexWrap: 'wrap',
          justifyContent: 'space-between',
        }}
      >
        <Typography level="h2"><Trans>Session {id}</Trans></Typography>
      </Box>
      <div>
        <div style={{ position: "relative" }}> {contents}</div>
      </div>
    </>
  
  );
}

export { Sessionpage };
